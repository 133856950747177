<script>
import * as Sentry from "@sentry/vue";

export default {
  name: "FAQPage",
  data() {
    return {
      faqRegistration: {},
      faqProgram: {},
    };
  },

  mounted() {
    fetch(
      this.$baseApiUrl +
        "/api/collections/get/njrFaqRegistration?token=" +
        process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = '/error';
        }
      })
      .then((data) => {
        this.faqRegistration = data.entries;
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = '/error';
      });

    fetch(
      this.$baseApiUrl +
        "/api/collections/get/njrFaqProgram?token=" +
        process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = '/error';
        }
      })
      .then((data) => {
        this.faqProgram = data.entries;
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = '/error';
      });
  },
};
</script>








<template>
  <div class="pageContainer">
    <div>
      <div class="mainSpace">
        <div>
          <div class="faqInfo" v-if="faqRegistration">
            <div class="headSpace">
              <h1 class="goldH1">FAQs</h1>
            </div>
            <div>
              <!-- <div class="regAndPricing"> -->
              <div class="spacer">
                <div class="wrapper">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="faqSection">
                        <h2 class="mediumBlue">Registration and Pricing</h2>
                      </div>
                    </div>

                    <ul class="col-md-8">
                      <li
                        v-for="(faq, index) in faqRegistration"
                        :key="faq.question"
                      >
                        <div class="accordion" id="accordionExample">
                          <div class="accordion-item">
                            <h2 class="accordion-header" id="label">
                              <button
                                class="accordion-button collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                v-bind:data-bs-target="'#collapseOne' + index"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                {{ faq.question }}
                              </button>
                            </h2>
                            <div
                              v-bind:id="'collapseOne' + index"
                              class="accordion-collapse collapse"
                              aria-labelledby="label"
                              data-bs-parent="#accordionExample"
                            >
                              <div
                                class="accordion-body"
                                v-html="faq.answer"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="programsFaq" v-if="faqProgram">
            <div clas="spacer">
              <div class="wrapper">
                <div class="row">
                  <div class="col-md-3">
                    <div class="faqSection">
                      <h2 class="mediumBlue">Program</h2>
                    </div>
                  </div>

                  <ul class="col-md-8">
                    <li v-for="(faq, index) in faqProgram" :key="faq.question" >
                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="label">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              v-bind:data-bs-target="'#collapseTwo' + index"
                              aria-expanded="true"
                              aria-controls="collapseTwo"
                            >
                              {{ faq.question }}
                            </button>
                          </h2>
                          <div
                            v-bind:id="'collapseTwo' + index"
                            class="accordion-collapse collapse"
                            aria-labelledby="label"
                            data-bs-parent="#accordionExample"
                          >
                            <div
                              class="accordion-body"
                              v-html="faq.answer"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  list-style: none;
}
.faqInfo {
  padding-top: 50px;
  background-color: #fbf7f3;
  padding-bottom: 75px;
}
.faqSection {
  /* margin-top: -50px; */
  padding: 1em;
  padding-left: 0px;
}
.programsFaq {
  margin-top: 43px;
  padding-top: 50px;
  padding-bottom: 80px;
}
.accordion-item {
  background-color: transparent;
  border: none;
}
.accordion-button {
  font-family: "GroteskMedium";
  color: #29326e;
  background-color: transparent;
  font-size: 17px;
  box-shadow: none;
  padding-left: 0px;
  border-bottom: 1px solid #b79b6e;
}
.accordion-button:focus {
  border-color: none;
  box-shadow: none;
}
.accordion-body {
  color: #17244b;
  font-family: "GroteskLight";
  font-size: 17px;
  padding-left: 0px;
}
.accordion-button::after {
  /* background-image: url("../assets/misc/plus.svg");
  background-position: center;
  background-size: contain;
  background-color: red;
  color: red;
  height: 100%;
  width: 100%; */
  background-image: none;
  content: "+";
  height: 17px;
  width: auto;
  transform: none;
  padding-left: 20px;
  color: #b79b6e;
}
.accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "-";
  height: 17px;
  width: auto;
  color: #b79b6e;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0px;
}
.accordion-item:first-of-type .accordion-button {
  border-radius: 0px;
}
/* >>> enables styles to be applied within the v-html (bc of way vue works) */
.faqInfo:deep(a), .programsFaq:deep(a) {
  text-decoration: none;
  font-family: "GroteskRegular";
  color: #b79b6e;
}
.accordion-body:deep(li), .accordion-body:deep(li) {
  padding-left: 8px;
}
.accordion-body:deep(li::marker), .accordion-body:deep(li::marker) {
  content: "\2023";
  color: #b79b6e;
  font-size: 24px;
}

@media (max-width: 575.98px) {
  .goldH1 {
    font-size: 8.5vw;
  }
  .accordion-button {
    font-size: 3.5vw;
    line-height: 4.5vw;
  }
  .accordion-button::after {
    height: 10vw;
    width: auto;

    font-size: 6vw;
    line-height: 6vw;
    font-weight: lighter;
  }
  .accordion-button:not(.collapsed)::after {
    height: 10vw;
    width: auto;

    font-size: 6vw;
    line-height: 6vw;
    font-weight: lighter;
  }
}

</style>
