<template>
    <h1>PreviousProgramsPage</h1>
</template>

<script>
export default {
  name: 'PreviousProgramsPage'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
