<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <div class="footer__icon-column">
        <a href="/"><img src="../assets/images/footer-logo-mountain.png" alt=""></a>
        <a href=" https://myjli.com/"><img src="../assets/images/footer-logo-book.png" alt=""></a>
      </div>
      <div class="footer__program-column">
        <h2>PROGRAM</h2>
        <ul>
          <li><a href="/faculty">Presenters</a></li>
          <li><a href="/highlights">Program Highlights</a></li>
          <li><a href="/childrens_program">Children’s Program</a></li>
          <li><a href="/schedule">Schedule</a></li>
          <li><a href="/wellness_institute">Wellness Summit</a></li>
        </ul>
      </div>
      <div class="footer__about-column">
        <h2>ABOUT</h2>
        <ul>
          <li><a href="/accommodations">Accommodations</a></li>
          <li><a href="/pricing">Pricing</a></li>
          <li><a href="/faq">FAQ</a></li>
        </ul>
      </div>
      <div class="footer__contact-column">
        <h2>Contact</h2>
        <ul>
          <li class="footer__address">
            <div>832 Eastern Parkway</div>Brooklyn, NY 11213
          </li>
          <li><a href="mailto:info@jretreat.com">info@jretreat.com</a></li>
          <li><a href="tel:+18775738732">1.877.573.8732</a></li>
        </ul>
      </div>

      <div class="footer__copyright-info">
        <p><span>© {{ new Date().getFullYear() }}. All rights reserved.</span> National Jewish Retreat™ - Registered
          Trademark of the Rohr Jewish Learning Institute.</p>
      </div>

    </div>
  </footer>
</template>


<script>
export default {
  name: "FooterComp",
};
</script>

<style scoped>
img,
svg {
  vertical-align: initial;
}

ul,
h2,
p {
  margin: 0;
  padding: 0;
}

.footer {
  background-color: var(--color-navy);
  padding-top: 64px;
  padding-bottom: 59px;
}

@media (max-width: 75em) {
  .footer {
    padding-top: 60px;
    padding-bottom: 55px;
  }
}

.footer__wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1128px;
  display: grid;
  grid-template-columns: 228px 576px 1fr;
  grid-template-rows: 166px 1fr;
  align-items: flex-start;
}

@media (max-width: 75em) {
  .footer__wrapper {
    grid-template-columns: minmax(35px, 1fr) 276px minmax(97px, 1fr);
    grid-template-rows: initial;
    margin-right: 0;
    margin-left: 0;
    max-width: initial;
    align-items: initial;
  }
}

.footer__copyright-info {
  background-color: var(--color-navy);
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-copyright-info-text);
  grid-row: 2 / 3;
  grid-column: 1 / -1;
  padding-top: 46px;
}

@media (max-width: 75em) {
  .footer__copyright-info {
    grid-row: 5 / 6;
    grid-column: 2 / 3;
    padding-top: 20px;
  }

  .footer__copyright-info p {
    width: 320px;
  }

  .footer__copyright-info span {
    display: block;
  }

}

.footer h2 {
  font-family: "GroteskRegular";
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--color-grey);
  text-transform: uppercase;
  padding-bottom: 12px;
}

@media (max-width: 75em) {
  .footer h2 {
    font-size: 16px;
    line-height: 30px;
    padding-bottom: 5px;
  }
}

.footer ul {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-footer-text-main);
  list-style: none;
}

@media (max-width: 75em) {
  .footer ul {
    font-size: 16px;
    line-height: 24px;
  }
}

.footer ul li {
  padding-bottom: 6px;
}

.footer ul a {
  text-decoration: none;
  color: inherit;
}

.footer__icon-column {
  width: 159px;
  display: flex;
  justify-content: space-between;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
}

.footer__address {
  color: var(--color-footer-text-secondary);
  padding-bottom: 16px !important;
}

.footer__program-column {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  justify-self: start;
}

.footer__about-column {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  justify-self: center;
  padding-left: 120px;
}

.footer__contact-column {
  grid-row: 1 / 2;
  grid-column: 3 / 4;
}

@media (max-width: 75em) {
  .footer__icon-column {
    grid-row: 4/5;
    grid-column: 2 / 3;
    padding-top: 37px;
  }

  .footer__program-column {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    justify-self: start;
  }

  .footer__about-column {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    justify-self: start;
    padding-left: 0;
    padding-top: 17px;
  }

  .footer__contact-column {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    padding-top: 17px;
  }
}
</style>