import { createApp } from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import App from './App.vue';

// const swup = new Swup();

const app = createApp(App);
Sentry.init({
  app,
  dsn: "https://737a9da84c1a41bdae1f6ab43e1688d0@o1043079.ingest.sentry.io/6293390",
  integrations: [
    new BrowserTracing({
    //   routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", process.env.VUE_APP_PRODUCTION_URL, /^\//],
    }),
  ],
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  replaysOnErrorSampleRate: 0.5,
});
app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });
app.config.globalProperties.$baseApiUrl = process.env.VUE_APP_BASE_API_URL;
app.mount('#app')








