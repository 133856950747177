<template>
  <div class="beigeBackground">
    <div class="page">
        <div v-if="scheduleEmbed">
          <h1 class="goldH1">
              Schedule
          </h1>
          <a id="sched-embed" :href="scheduleEmbed" > <p style="margin-top: 75px;">View the National Jewish Retreat schedule &amp; directory.</p></a>
        </div>
        <span v-else>
          <h1 class="goldH1 coming-soon">
              Coming Soon...
          </h1>
        </span>

        <a :href="previousRetreatDetails.scheduleBrochureUrl" >
          <p class="regularBlue" style="margin-top: 75px;">View last year's schedule</p>
        </a>
    </div>
  </div> 
</template>   

<script>
import * as Sentry from "@sentry/vue";

export default {
  name: "SchedulePage",

  data() {
    return {
      scheduleEmbed: true,
      previousRetreatDetails: {},
      scriptAdded: false,
    }; 
  },
  mounted() {
    fetch(
      process.env.VUE_APP_BASE_API_URL +
        "/api/singletons/get/njrPreviousRetreatDetails?token=" +
        process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = "/error";
        }
      })
      .then((data) => {
        this.previousRetreatDetails = data;
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = "/error";
      });

    fetch(
      this.$baseApiUrl +
        "/api/singletons/get/njrRetreatDetails?token=" +
        process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = '/error';
        }
      })
      .then((data) => {
        this.scheduleEmbed = data.scheduleEmbed ? data.scheduleEmbed : false;
        
        if (!this.scriptAdded) {
          let recaptchaScript = document.createElement('script')
          recaptchaScript.setAttribute('src', `${this.scheduleEmbed}/js/embed.js`)
          document.body.appendChild(recaptchaScript)   
          // Set the flag to true to prevent adding the script multiple times
          this.scriptAdded = true; 
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = '/error';
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.page {
  /* height: 100vh; */
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
a {
  text-decoration: none;
}
.coming-soon {
  margin-bottom: 50px;
}
.regularBlue:hover {
  color: #17244bc0;
}
embed, iframe {
  margin-bottom: 35px;
  margin-top: 35px;
  padding: 10px;
  border: 1px solid #b79b6e;
}
@media (max-width: 575.98px) {
  .page {
    padding-top: 200px;
  }
}
@media (max-width: 459.98px) {
  .page h1 {
    font-size: 46px;
  }
}
</style>