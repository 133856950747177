<template>
  <div class="biegeBackground">
    <h1>RegisterPage</h1>
  </div>
</template>

<script>
export default {
  created() {
    window.location.href = "https://myjli.com/retreat/";
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
