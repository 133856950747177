<template>
  <div class="pageContainer">
    <header>
      <div class="accomodationHeaderBkgd">
        <div class="row rowAcom">
          <div class="col-6">
            <img
              :src="`${$baseApiUrl}${imagePaths.kidsAccommodationImage1Path}`"
              alt=""
              class="img-fluid"
            />
          </div>
          <div class="col-6">
            <img
              :src="`${$baseApiUrl}${imagePaths.kidsAccommodationImage2Path}`"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
        <div class="wrapper">
          <div class="spaceOut headSpace">
            <h1 class="goldH1">Childrens' Program</h1>
          </div>
        </div>
      </div>
    </header>
    <div class="mainSpace" v-if="kidDetails">
      <div class="pageInfo">
        <div class="kidContent">
          <div class="wrapper">
            <div>
              <ul>
                <li
                  v-for="(kid, index) in kidDetails"
                  :key="kid.title"
                  class="pageSecContainer"
                >
                  <div class="pageSec" v-bind:data-bs-target="index">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="goldenH2">
                          <h2>{{ kid.title }}</h2>
                        </div>
                        <div>
                          <h4 class="goldH4">{{ kid.subtitle }}</h4>
                        </div>
                      </div>
                      <div class="col-md-8">
                        <div class="lightBlueP">
                          <p v-html="kid.description"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import * as Sentry from "@sentry/vue";

export default {
  name: "KidsProgramPage",
  data() {
    return {
      kidDetails: {},
      locationDetails: {},
      imagePaths: {
        kidsAccommodationImage1Path: "",
        kidsAccommodationImage2Path: "",
      },
    };
  },
  mounted() {
    fetch(
      this.$baseApiUrl +
        "/api/collections/get/njrKidsProgram?token=" +
        process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = '/error';
        }
      })
      .then((data) => {
        this.kidDetails = data.entries;
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = '/error';
      });
    fetch(
      this.$baseApiUrl +
        "/api/singletons/get/njrLocationDetails?token=" +
        process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = '/error';
        }
      })
      .then((data) => {
        this.locationDetails = data;
        this.imagePaths.kidsAccommodationImage1Path =
          data.kidsAccommodationImage1.path;
        this.imagePaths.kidsAccommodationImage2Path =
          data.kidsAccommodationImage2.path;
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = '/error';
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row.rowAcom {
  --bs-gutter-x: 0rem;
}
ul {
  padding-left: 0px;
}
li {
  list-style: none;
}
.goldenH2 {
  color: #b79b6e;
  font-family: "CanelaRegular";
}
.kidContent {
  padding-bottom: 200px;
}
.pageSecContainer:last-child .pageSec {
  border: 0;
}

.kidContent:deep(a),
.kidContent:deep(a) {
  text-decoration: none;
  font-family: "GroteskRegular";
  color: #b79b6e;
}

@media (max-width: 575.98px) {
  .spaceOut {
    padding: 50px 0px;
  }
  .kidInfo {
    padding-top: 40px;
  }
}
</style>
