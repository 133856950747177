<template>
  <div class="beigeBackground">
    <div class="fof">
      <h1 class="goldH1">Error 404</h1>
      <div>
        <p class="mediumBlue">This page does not exist!</p>
      </div>
    </div>
  </div>
</template>


 <script>
export default {
  name: "NotFound",
  props: {},
};
</script>
<style scoped>
* {
  transition: all 0.6s;
}
.fof {
  height: 100vh;
  text-align: center;
  padding-top: 300px;
  /* position: relative; */
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #b7936eaa;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
@media (max-width: 575.98px) {
  .fof {
    padding-top: 200px;
  }
}
</style>