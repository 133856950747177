<template>
  <div class="pageContainer">
    <div class="beigeBackground">
      <div class="highlightHead">
        <div class="wrappers">
          <div class="headSpace">
            <h1 class="goldH1">{{ programDetails.highlightsTitle }}</h1>
          </div>
          <div class="headSpaces">
            <div>
              <div
                id="carouselExampleSlidesOnly"
                class="carousel slide"
                data-bs-ride="carousel"
              >
                <div class="headUl">
                  <div v-if="programDetails.entertainment">
                    <div class="carousel-inner">
                      <div
                        v-for="(
                          entertain, index
                        ) in programDetails.entertainment"
                        :key="entertain.title"
                        class="carousel-item"
                        :class="{ active: index == 0 }"
                      >
                        <div class="row">
                          <div class="col-md-8">
                            <img
                              :src="
                                $baseApiUrl + '/' + entertain.value.image.path
                              "
                              class="img-fluid"
                            />
                          </div>
                          <div class="col-md-4 programInfo">
                            <h3 class="programType">{{ programDetails.highlightsSubTitle }}</h3>
                            <p class="programPerson">
                              {{ entertain.value.title }}
                            </p>
                            <p class="programDescription">
                              {{ entertain.value.description }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mainSpace" v-if="programDetails.talks">
      <div class="wrappers">
        <div class="spaceHighlight">
          <ul class="row rowl">
            <!-- if you need to add colSpace it would go back here -->
            <li
              v-for="(talk, index) in programDetails.talks"
              :key="talk.title"
              class="col-lg-4 col-md-6"
            >
              <button
                type="button"
                class="btn"
                data-bs-toggle="modal"
                v-bind:data-bs-target="'#exampleModal' + index"
              >
                <div class="highlightSpace">
                  <div class="mainHigh">
                    <div class="goldBorder goldBottom clearBorder">
                      <div class="image">
                        <img
                          :src="$baseApiUrl + talk.value.image.path"
                          class="img-fluid moobileImg"
                        />
                      </div>
                      <div class="talkInfo leftAlign">
                        <div class="talkTitle">
                          {{ talk.value.title }}
                        </div>
                        <!-- <div class="d-none d-lg-block"> -->
                        <div v-show="talk.value.description.length >= 150">
                          <div class="talkDescription">
                            <div class="talkPSace">
                              <p class="pSize">
                                {{ talk.value.description }}
                              </p>
                            </div>
                            <div class="readMore">Read more</div>
                            <!-- <div class="spaceTests"></div> -->
                          </div>
                        </div>
                        <div v-show="talk.value.description.length < 150">
                          <div class="talkDescription">
                            <div class="talkPSace">
                              <p class="pSize">
                                {{ talk.value.description }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <!-- </div> -->
                        <!-- <div class="d-lg-none">
                          <div v-show="talk.value.description.length >= 220">
                            <div class="talkDescription">
                              <div class="talkPSace">
                                <p class="pSize">
                                  {{ talk.value.description }}
                                </p>
                              </div>
                              <div class="readMore">Read more</div>
                            </div>
                          </div>
                          <div v-show="talk.value.description.length < 220">
                            <div class="talkDescription">
                              <div class="talkPSace">
                                <p class="pSize">
                                  {{ talk.value.description }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div> -->
                      </div>

                      <!-- code for main options -->
                      <div
                        class="groupTest"
                        v-show="
                          talk.value.speakers && talk.value.speakers.length < 2
                        "
                      >
                        <div
                          v-for="speaking in talk.value.speakers"
                          :key="speaking.name"
                        >
                          <div class="bottomHigh">
                            <div class="subHigh">
                              <div class="row moRow">
                                <div class="col-3 speakerPicture">
                                  <img
                                    :src="
                                      $baseApiUrl + speaking.value.image.path
                                    "
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="col-9 speak leftAlign">
                                  <p class="speakerName">
                                    {{ speaking.value.name }}
                                  </p>

                                  <p
                                    v-html="speaking.value.description"
                                    class="speakerBio pSize"
                                  ></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- code for 3+ speakers -->
                      <div
                        class="groupTest"
                        v-show="
                          talk.value.speakers && talk.value.speakers.length >= 2
                        "
                      >
                        <div class="bottomHigh">
                          <div class="subHigh">
                            <div class="linePhotos">
                              <ul class="row slideOver">
                                <li
                                  v-for="speaking in talk.value.speakers"
                                  :key="speaking.name"
                                  class="col-3 spaceCol"
                                >
                                  <div class="speakerPicture">
                                    <img
                                      :src="
                                        $baseApiUrl + speaking.value.image.path
                                      "
                                      class="img-fluid"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
              <div
                class="modal fade highlightSpaces"
                v-bind:id="'exampleModal' + index"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modalBox">
                      <div class="modal-body">
                        <div class="mainHighs">
                          <div>
                            <div class="image">
                              <img
                                :src="$baseApiUrl + talk.value.image.path"
                                class="img-fluid roundImg"
                              />
                            </div>

                            <div class="modalPad">
                              <div class="spaceFix">
                                <div class="talkInfo">
                                  <div class="talkTitle">
                                    {{ talk.value.title }}
                                  </div>

                                  <div class="talkDescription modalDesc">
                                    <p>{{ talk.value.description }}</p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              v-show="
                                talk.value.speakers &&
                                talk.value.speakers.length >= 1
                              "
                            >
                              <div class="goldB"></div>
                            </div>
                            <div class="modalPads">
                              <div class="modalBot">
                                <div
                                  v-for="speaking in talk.value.speakers"
                                  :key="speaking.name"
                                >
                                  <div class="bottomHighs">
                                    <div class="subHighs">
                                      <div class="row rSpace">
                                        <div class="col-3 speakerPicture">
                                          <img
                                            :src="
                                              $baseApiUrl +
                                              speaking.value.image.path
                                            "
                                            class="img-fluid imgB"
                                          />
                                        </div>
                                        <div class="col-9 speak speakM">
                                          <p class="speakerName">
                                            {{ speaking.value.name }}
                                          </p>

                                          <p
                                            class="speakerBios"
                                            v-html="speaking.value.description"
                                          ></p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

export default {
  name: "AboutTheProgramPage",
  data() {
    return {
      programDetails: {},
    };
  },
  // computed() {
  //   speakerAmount: function(speakers) {

  //     return speakers.length
  //   }
  // },
  mounted() {
    fetch(
      this.$baseApiUrl +
        "/api/singletons/get/njrHighlights?token=" +
        process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = "/error";
        }
      })
      .then((data) => {
        this.programDetails = data;
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = "/error";
      });
    fetch(
      this.$baseApiUrl +
        "/api/singletons/get/njrHighlights?token=" +
        process.env.VUE_APP_API_TOKEN
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*:focus {
  outline: 0 !important;
  box-shadow: none !important;
}
.wrappers {
  margin-right: auto;
  margin-left: auto;
  max-width: 1100px;
  /* padding-right: 20px;
  padding-left: 20px; */
  width: 90%;
}
.highlightHead {
  padding-top: 100px;
}
.headSpaces {
  padding-top: 130px;
  padding-bottom: 120px;
}
ul {
  list-style-type: none;
}
/* ul.headUl {
  padding-left: 0rem;
} */
.rightS {
  padding-right: 0px;
}
.carousel-item {
  transition: transform 1.3s ease-in-out;
}
.programInfo {
  padding-left: 36px;
  padding-right: 34px;
}
.programType {
  font-size: 1.6em;
  font-family: "GroteskMedium";
  letter-spacing: 2px;
  color: #b99d6c;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(185, 157, 108, 0.3);
  /* display: inline; */
  padding-bottom: 12px;
}
.programPerson {
  padding-top: 30px;
  font-size: 20px;
  font-family: "GroteskSemibold";
  color: #17244b;
  margin-bottom: 12px;
}
.programDescription {
  font-size: 20px;
  font-family: "GroteskLight";
  line-height: 1.4;
  color: #17244b;
}
.spaceHighlight {
  padding-top: 120px;
}
.row.rowl > * {
  padding-right: 0px;
  padding-left: 5px;
}
.rowl {
  padding-right: 0px;
  padding-left: 0px;
}
/* .colSpace {
  padding-bottom: 100px;
} */
.btn {
  padding-left: 5px;
}
.highlightSpace {
  /* padding-bottom: 131px; */
  padding-bottom: 110px;
}

.mainHigh {
  position: relative;
  display: flex;
  /* height: 100%; */
  /* height: 700px; */
  height: 650px;
  width: 100%;
  margin-bottom: 10px;
}
.mainHighs {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  margin-bottom: 10px;
}
li:nth-child(n) .goldBorder {
  border-right: 1px solid rgba(158, 134, 111, 0.2);
  padding-right: 18px;
  height: 100%;
  /* height: 644px; */
}
li:nth-child(3n) .clearBorder {
  border-right: 1px solid transparent;
  padding-right: 18px;
  height: 100%;
  /* max-height: 644px; */
}
.leftAlign {
  text-align: left;
}
.talkTitle {
  font-family: "GroteskSemibold";
  font-size: 18px;
  line-height: 25px;
  color: #17244b;
  padding-top: 19px;
  padding-bottom: 15px;
}
.talkDescription {
  font-family: "GroteskLight";
  font-size: 16px;
  line-height: 22px;
  color: #17244b;
  padding-bottom: 27px;
}
.talkPSace {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.pSize {
  margin-bottom: 0rem;
}
.readMore {
  font-family: "GroteskRegular";
  font-size: 16px;
  line-height: 22px;
  color: #17244b73;
  padding-top: 10px;
}
.readMore:hover {
  color: #17244bd8;
}

/* CSS to diplay speaker and bio at the end up the col */
.groupTest {
  padding-top: 17px;
}
.bottomHigh {
  padding-top: 100px;
}
.subHigh {
  position: absolute;
  bottom: 0px;
}
/* .subHighs {
} */
.speakerPicture {
  padding-right: 5px;
  /* padding-bottom: 4px; */
  /* padding-bottom: 23px; */
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  position: relative;
  /* max-height: 77px; */
}
.col-9.speak {
  width: 71%;
}
.speakerName {
  font-family: "GroteskMedium";
  font-size: 13px;
  line-height: 16px;
  color: #17244b;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}
.speakerBio {
  font-family: "GroteskLight";
  font-size: 13px;
  line-height: 18px;
  color: #17244b;
}
.speakerBios {
  font-family: "GroteskLight";
  font-size: 13px;
  line-height: 18px;
  color: #17244b;
  margin-bottom: 0px;
}

/* code for multiple speakers */
.linePhotos {
  padding-left: 10px;
  padding-right: 25px;
}
.slideOver {
  padding-left: 0rem;
}
.spaceCol {
  padding-right: 2px;
  padding-left: 2px;
}

/* CSS for the modal */
.highlightSpaces {
  background-color: #0c1329;
  opacity: 100%;
  padding-top: 120px;
  padding-bottom: 122px;
}
.modal-content {
  background-color: transparent;
  border-radius: none;
  border: none;
}
.modal-header {
  border-bottom: none;
  background-color: transparent;
}
.btn-close {
  filter: invert(59%) sepia(61%) saturate(241%) hue-rotate(360deg)
    brightness(97%) contrast(87%);
}
/* .modalBox {
  box-shadow: 0px 25px 30px #0c1329;
} */
.modal-body {
  background-color: #fff;
  padding: 0rem;
  border-radius: 10px;
}
.roundImg {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.modalPad {
  padding: 0px 21px;
  background-color: #fbf7f3;
  /* border-radius: 10px; */
}
.modalPads {
  padding: 0px 21px;
  background-color: #fbf7f3;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.spaceFix {
  padding: 1rem;
}
.modalDesc {
  padding-bottom: 0px;
}
.goldB {
  border-bottom: 1px #9e866f solid;
  opacity: 20%;
}
.modalBot {
  padding: 0px 16px;
  padding-bottom: 24px;
  padding-top: 34px;
  /* padding-bottom: 16px; */
  /* padding-bottom: 8px; */
}
.rSpace {
  /* padding-top: 16px; */
  /* padding-top: 34px; */
  /* padding-bottom: 18px; */
  padding-top: 0px;

  padding-bottom: 10px;
}
.imgB {
  max-width: 77px;
}
.speakM {
  margin-left: -15px;
  /* margin-left: 10px; */
}

@media (max-width: 1199px) {
  .programInfo {
    padding-right: 33px;
  }
  .highlightSpace {
    padding-bottom: 85px;
  }
}
@media (max-width: 1150px) {
  .mainHigh {
    height: 606px;
  }
}
@media (max-width: 1044px) {
  .programType {
    font-size: 1em;
  }
  .mainHigh {
    height: 586px;
  }
}
@media (max-width: 991.98px) {
  .mainHigh {
    height: 738px;
  }
  li:nth-child(odd) .goldBorder {
    border-right: 1px solid rgba(158, 134, 111, 0.2);
    padding-right: 18px;
    height: 100%;
  }
  li:nth-child(even) .clearBorder {
    border-right: 1px solid transparent;
    padding-right: 18px;
    height: 100%;
  }
}
@media (max-width: 767.98px) {
  /* .colSpace {
    padding-bottom: 30px;
  } */
  .programInfo {
    padding-top: 24px;
    padding-left: 12px;
    padding-right: 10px;
  }
  .programType {
    font-size: 1.6em;
  }
  .spaceHighlight {
    padding-top: 75px;
  }
  .btn {
    padding-left: 7px;
  }
  .highlightSpace {
    padding-bottom: 29px;
  }
  .mainHigh {
    height: 957px;
    /* height: 50vh; */
  }
  li:nth-child(n) .goldBorder {
    border-right: 0;
    padding-right: 0;
  }
  li:nth-child(n) .clearBorder {
    border-right: 0;
    padding-right: 0;
  }
  .goldBottom {
    border-bottom: 1px solid rgba(158, 134, 111, 0.2);
    padding-bottom: 18px;
  }
  .redMore {
    padding-top: 5px;
  }
  .speakerPicture {
    max-width: 100px;
  }
  .moRow {
    padding-bottom: 15px;
  }
}
@media (max-width: 680px) {
  .mainHigh {
    height: 908px;
  }
}
@media (max-width: 600px) {
  .mainHigh {
    height: 820px;
  }
}
@media (max-width: 510px) {
  .mainHigh {
    height: 726px;
  }
}
@media (max-width: 468px) {
  .mainHigh {
    height: 680px;
  }
}
@media (max-width: 757.98px) {
  /* .mainHigh {
    height: 824px;
  } */
}
@media (max-width: 428.98px) {
  .spaceTests {
    margin-bottom: -10px;
  }
  .mainHigh {
    height: 616px;
  }
  .moRow {
    padding-bottom: 10px;
  }
  ul {
    padding-left: 0rem;
  }
  .headUl {
    padding-right: 26px;
    padding-left: 22px;

    /* s */
  }
  .btn {
    padding: 0.375rem 0.75rem;
    padding-right: 35px;
    padding-left: 35px;
  }
  .groupTest {
    padding-top: 20px;
  }
  .linePhotos {
    padding-right: 6px;
  }

  .headSpaces {
    padding-top: 43px;
    padding-bottom: 36px;
  }
  .programType {
    font-size: 16px;
    letter-spacing: 1.28px;
    line-height: 16px;
  }
  .programPerson {
    font-size: 19px;
    line-height: 19px;
  }
  .programDescription {
    font-size: 15px;
    line-height: 20px;
  }
  .spaceHighlight {
    padding-top: 43px;
  }
  .highlightSpace {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .colSpace {
    padding-bottom: 0px;
  }
  .talkDescription {
    padding-bottom: 0px;
  }
  .speakM {
    margin-left: 0px;
  }
  .goldBottom {
    padding-bottom: 27px;
  }
  /* 
  .modalBox {
    box-shadow: 0px 25px 30px #0c1329;
  } */
  .speakerPicture {
    height: 77px;
  }
}
@media (max-width: 400px) {
  .mainHigh {
    height: 589px;
  }
}
</style>
