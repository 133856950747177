<template>
    <div class="navbar-margin">


    </div>
</template>
  
  
<script>
export default {
    name: "NavBarMargin"
};
</script>
  
  
  
<style scoped>
.navbar-margin {
    height: 126.75px;
}

@media (max-width: 62.5em) {
    .navbar-margin {
        height: 96px;
    }
}
</style>
  