<template>
  <template v-if="showNav(currentView.name)">
    <NavBar :disable-wellness-page="disableWellnessPage" />
    <NavBarMargin />
  </template>

  <component :is="currentView" />
  <FooterComp />
</template>

<script>
import * as Sentry from "@sentry/vue";
import NavBar from "./components/NavBar.vue";
import NavBarMargin from "./components/NavBarMargin.vue";
import NotFound from "./components/NotFound.vue";
import FooterComp from "./components/FooterComp.vue";
import AccommodationsPage from "./components/AccommodationsPage.vue";
import MainPage from "./components/MainPage.vue";
import KidsProgramPage from "./components/KidsProgramPage.vue";
import ContactPage from "./components/ContactPage.vue";
import PreviousProgramsPage from "./components/PreviousProgramsPage.vue";
import SchedulePage from "./components/SchedulePage.vue";
import MedicalEthicsPage from "./components/MedicalEthicsPage.vue";
import FacultyPage from "./components/FacultyPage.vue";
import PricingPage from "./components/PricingPage.vue";
import FAQPage from "./components/FAQPage.vue";
import AppPage from "./components/AppPage.vue";
import CancellationPolicyPage from "./components/CancellationPolicyPage.vue";
import ShluchimInfoPage from "./components/ShluchimInfoPage.vue";
import RegisterPage from "./components/RegisterPage.vue";
import AboutTheProgramPage from "./components/AboutTheProgramPage.vue";
import WellnessInstitutePage from "./components/WellnessInstitutePage.vue";
import SinaiScholarsPage from "./components/SinaiScholarsPage.vue";
import ErrorPage from "./components/ErrorPage.vue";
import { provide } from 'vue';

const routes = {
  "/accommodations": AccommodationsPage,
  "/childrens_program": KidsProgramPage,
  "/previous_programs": PreviousProgramsPage,
  "/medical_ethics": MedicalEthicsPage,
  "/schedule": SchedulePage,
  "/highlights": AboutTheProgramPage,
  "/faculty": FacultyPage,
  "/contact": ContactPage,
  "/pricing": PricingPage,
  "/faq": FAQPage,
  "/register": RegisterPage,
  "/cancellation": CancellationPolicyPage,
  "/shluchim": ShluchimInfoPage,
  "/wellness_institute": WellnessInstitutePage,
  "/sinai_scholars": SinaiScholarsPage,
  "/error": ErrorPage,
  "/not_found": NotFound,
  "/main": MainPage,
  "/app": AppPage,
  "/": MainPage,

  // Catch-all
  // '*': NotFound
};

export default {
  name: "App",
  setup() {
    provide('$baseApiUrl', process.env.VUE_APP_BASE_API_URL);
  },
  components: {
    NavBar,
    NavBarMargin,
    FooterComp,
  },
  //handling routing
  data() {
    return {
      currentPath: window.location.pathname.toLowerCase(),
      disableWellnessPage: false
    };
  },
  computed: {
    currentView() {
      if (this.currentPath==='/wellness_institute' && this.disableWellnessPage) return NotFound;
      if (this.currentPath === '/about_the_program')  window.location.href = "/highlights"; // For backwards compatibility

      return routes[this.currentPath || "/"] || NotFound;
    },
  },
  methods: {
    generatePageTitle: function (currentPath) {
      var pageTitle = "NJR - ";
      switch (currentPath) {
        case "/faq":
          pageTitle += "FAQ";
          break;
        case "/":
        case "/main":
          pageTitle += "National Jewish Retreat";
          break;
        default:
          if (!routes[currentPath]) {
            pageTitle += "National Jewish Retreat";
          } else {
            pageTitle += currentPath
              .split("/")
              .pop()
              .replace("_", " ")
              .replace(/(?:^|\s)\S/g, function (a) {
                return a.toUpperCase();
              });
          }
          break;
      }
      return pageTitle;
    },
    showNav: function (viewName) {
      if (viewName != "WellnessInstitutePage") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    document.title = this.generatePageTitle(this.currentPath);


    fetch(
      this.$baseApiUrl +
      "/api/singletons/get/njrWellnessSummit?token=" +
      process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          this.currentPath = '/error';
        }
      })
      .then((data) => {
        this.disableWellnessPage = data.disableWellnessPage;
      })
      .catch((err) => {
        Sentry.captureException(err);
        this.currentPath = '/error';
      });
  },
};
</script>
 
<style>
/* Additional fonts */
/********************/
/* Grotesk Family   */

/* Font Weight = 300 */
@font-face {
  font-family: "GroteskLight";
  src: url("assets/fonts/FoundersGroteskText-Light.otf");
}

/* Font Weight = 400 */
@font-face {
  font-family: "GroteskRegular";
  src: url("assets/fonts/FoundersGroteskText-Regular.otf");
}

/* Font Weight = 600 */
@font-face {
  font-family: "GroteskSemiBold";
  src: url("assets/fonts/FoundersGroteskText-Semibold.otf");
}

/* Font Weight = 700 */
@font-face {
  font-family: "GroteskBold";
  src: url("assets/fonts/FoundersGroteskText-Bold.otf");
}

/* Font Weight = 500 */
@font-face {
  font-family: "GroteskMedium";
  src: url("assets/fonts/FoundersGroteskText-Medium.otf");
}

/* Canela Family */

@font-face {
  font-family: "CanelaLightItalic";
  src: url("assets/fonts/Canela-LightItalic.otf");
}

/* Font Weight = 100 */
@font-face {
  font-family: "CanelaThin";
  src: url("assets/fonts/Canela-Thin.otf");
}

/* Font Weight = 300 */
@font-face {
  font-family: "CanelaLight";
  src: url("assets/fonts/Canela-Light.otf");
}

/* Font Weight = 400 */
@font-face {
  font-family: "CanelaRegular";
  src: url("assets/fonts/Canela-Regular.otf");
}

/* Freight Family */
@font-face {
  font-family: "FreightSemiBold";
  src: url("assets/fonts/Freight Neo Pro Semibold.otf");
}

@font-face {
  font-family: "FreightBookItalic";
  src: url("assets/fonts/Freight Neo Pro Book Italic.otf");
}

@font-face {
  font-family: "FreightMedium";
  src: url("assets/fonts/Freight Neo Pro Medium.otf");
}

@font-face {
  font-family: "FreightMediumItalic";
  src: url("assets/fonts/Freight Neo Pro Medium Italic.otf");
}

/* IdealSans Family */
@font-face {
  font-family: "IdealSemiBold";
  src: url("assets/fonts/IdealSans-Semibold-Pro.otf");
}

@font-face {
  font-family: "IdealLight";
  src: url("assets/fonts/IdealSans-Light-Pro.otf");
}

@font-face {
  font-family: "IdealMedium";
  src: url("assets/fonts/IdealSans-Medium-Pro.otf");
}

@font-face {
  font-family: "IdealLightI";
  src: url("assets/fonts/IdealSans-LightItalic-Pro.otf");
}

@font-face {
  font-family: "IdealBook";
  src: url("assets/fonts/IdealSans-Book-Pro.otf");
}

/* Aperto */
@font-face {
  font-family: "ApertoBold";
  src: url("assets/fonts/Aperto Bold.ttf");
}

html,
body {
  width: auto !important;
  overflow-x: hidden !important;
  /* removes padding that is added by modal library */
  padding-right: 0 !important;
}

.bh {
  text-align: right;
  color: #29326e;
  margin-right: 10px;
}

.wrapper {
  margin-right: auto;
  /* centers content */
  margin-left: auto;
  /* centers content */
  max-width: 1100px;
  /* sets width of content */
  padding-right: 20px;
  /* lil padding */
  padding-left: 20px;
  /* lil padding */
  width: 90%;
}

.goldH1 {
  color: #b79b6e;
  font-family: "CanelaRegular";
  font-size: 56px;
}

.goldH4 {
  color: #b79b6e;
  font-family: "GroteskLight";
  font-size: 16px;
}

.centerHeader {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 25px;
}

.beigeBackground {
  background: #fbf7f3;
}

.headSpace {
  text-align: center;
}

.spaceOut {
  padding-top: 100px;
  padding-bottom: 100px;
}

.spacer {
  margin-top: 100px;
}

.pageInfo {
  background: #fbf7f3;
  padding-top: 80px;
  padding-bottom: 100px;
}

.pageSec {
  border-bottom: #b79b6e solid 1px;
  margin-top: 50px;
}

.lightBlue {
  font-family: "GroteskLight";
  color: #17244b;
  font-size: 20px;
  /* margin-bottom: 50px; */
  font-weight: 500;
}

.lightBlueP {
  font-family: "GroteskLight";
  color: #17244b;
  font-size: 20px;
  margin-bottom: 50px;
  font-weight: 500;
}

.mediumBlue {
  color: #17244b;
  font-family: "GroteskMedium";
  font-size: 24px;
}

.regularBlue {
  font-family: "GroteskRegular";
  color: #17244b;
  font-size: 20px;
}

.grecaptcha-badge {
  opacity: 0;
}

@media (max-width: 991.98px) {
  .spaceOut {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media (max-width: 767.98px) {
  .spacer {
    margin-top: 50px;
  }
}

/**************************************************************/
/* CSS for different breakpoints */
/**************************************************************/
@media (max-width: 575.98px) {
  .pageInfo {
    padding-top: 20px;
  }

  .goldH1 {
    font-size: 8vw;
  }

  .goldH4 {
    font-size: 17px;
  }

  /* .goldenH2 {
      font-size: 7vw;
      padding-bottom: 10px;
    } */
  .pageSec {
    padding-bottom: 30px;
  }

  .lightBlueP {
    font-size: 18px;
  }
}

@media (max-width: 459px) {
  .goldH1 {
    font-size: 9vw;
  }
}

/**************************************************************/
/* CSS custom properties (variables) for colors */
/**************************************************************/

:root {
  --color-dark-gold: #9B845B;

  --color-peach: #F5E7DA;

  --color-gold: #B99D6C;

  --color-navy: #0E1939;

  --color-beige: #FAF6F3;

  --color-white: #FFFFFF;

  --color-black: #000000;

  --color-blue: #17244B;

  --color-light: #FBF7F3;

  --color-grey: #9498A7;

  --color-footer-text-secondary: #FBF5F0;

  --color-footer-text-main: #F1EBE3;

  --color-copyright-info-text: #F2E4D6;
}
</style>