<script setup>
import * as Sentry from "@sentry/vue";
import { ref, onMounted, inject, computed, onBeforeUnmount } from 'vue';

const retreatData = ref({});
const albumDetails = ref({});
const testimonialDetails = ref({});
const facultyDetails = ref({});
const currentFaculty = ref({});
const videoSrc = ref('');
const videoTitle = ref('');
const englishDates = ref("");
const hebrewDates = ref("");
const presentersAmount = ref('');
const workshopsAmount = ref('');
const attendeesAmount = ref('');
const venueDescriptionParagraph = ref('');
const talksDescriptionParagraph = ref('');
const diningDescriptionParagraph = ref('');
const communityDescriptionParagraph = ref('');
const talksImageURL = ref('');
const detailsSectionImageURL = ref('');
const communityImageURL = ref('');
const cuisineLargeImageURL = ref('');
const cuisineSmallImageURL = ref('');
const sliderContainer = ref(null);
const sliderTrack = ref(null);
let currentPosition = 0;
let slideWidth = 0;
const baseApiUrl = inject('$baseApiUrl');
const locationDetails = ref([]);
let imageCount = 3;
const venueImages = ref([]);
const facultyDescription = ref('');
const registrationTagline = ref('');
const jumboVideoUrl = ref('');

const showJumboVideo = ref(false);


// Jumbo Video Code
import jumboImgUrl from '@/assets/images/header-picture-dark.png';

const hideJumboVideo = () => {
  // console.error("video error:", err); 
  showJumboVideo.value=false; 
}; 



// Venue Slider Code
const currentImageIndex = ref(0);
const transitionName = ref('slide-left');

const currentImageSrc = computed(() => venueImages?.value[currentImageIndex.value]?.value.path);

// To change the interval between one picture and the next change this number. Number is number of milliseconds
function startImageSlider() {
  imageSliderInterval = setInterval(slideNext, 4000);
}

function slideNext() {
  transitionName.value = 'slide-left';
  currentImageIndex.value = (currentImageIndex.value + 1) % venueImages.value.length;
}

let imageSliderInterval;

onMounted(startImageSlider);

onBeforeUnmount(() => {
  clearInterval(imageSliderInterval);
});

// End of Venue Slider code
const pauseVideo = () => {
  videoSrc.value = "";
};
const playVideo = () => {
  videoSrc.value = retreatData.value.recapVideoUrl;
};
const navigateOnClick = (url) => {
  window.location.href = url;
};
const calculateBackground = (location) => {
  return {
    backgroundImage: `url(${baseApiUrl + location})`,
    backgroundColor: "#f5e7da"
  }
};
const nextButtonClick = () => {
  calculateImageCount();
  slideWidth = getSliderWidth();
  currentPosition -= slideWidth;
  if (currentPosition < - slideWidth * (sliderTrack.value.children.length - imageCount)) {
    currentPosition = 0;
  }
  sliderTrack.value.style.transform = `translateX(${currentPosition}px)`;
};

const prevButtonClick = () => {
  calculateImageCount();
  slideWidth = getSliderWidth();
  currentPosition += slideWidth;
  if (currentPosition > 0) {
    currentPosition = -slideWidth * (sliderTrack.value.children.length - imageCount);
  }
  sliderTrack.value.style.transform = `translateX(${currentPosition}px)`;
};

const calculateImageCount = () => {
  imageCount = document.documentElement.clientWidth > 1300 ? 3 : 2;
}

const getSliderWidth = () => {
  return sliderContainer.value.clientWidth / imageCount;
}

// Testimonial Slider Code
const currentIndex = ref(0);
const currentMessage = computed(() => testimonialDetails?.value.entries?.[currentIndex.value]);

const switchTestimonial = () => {
  currentIndex.value = (currentIndex.value + 1) % testimonialDetails?.value?.entries?.length;
};

const beforeEnter = (el) => {
  el.style.transform = 'translateX(100%)';
};

const enter = (el, done) => {
  el.style.transition = 'transform 1s';
  setTimeout(() => {
    el.style.transform = 'translateX(0)';
    done();
  }, 0);
};

const leave = (el, done) => {
  el.style.transition = 'transform 1s';
  el.style.transform = 'translateX(-100%)';
  setTimeout(done, 1000);
};


onMounted(() => {
  // To change the interval between one testimonial message and the next change this number. Number is number of milliseconds
  setInterval(() => {
    switchTestimonial();
  }, 5000);

  fetch(
    baseApiUrl +
    "/api/singletons/get/njrRetreatDetails?token=" +
    process.env.VUE_APP_API_TOKEN
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        Sentry.captureException(response);
        window.location.href = "/error";
      }
    })
    .then((data) => {
      retreatData.value = data;
      registrationTagline.value = data.registratioTagline;
      videoSrc.value = data.recapVideoUrl;
      englishDates.value = data.englishDates;
      hebrewDates.value = data.hebrewDates;
      presentersAmount.value = data.presenters;
      workshopsAmount.value = data.workshops;
      attendeesAmount.value = data.attendees;
      venueDescriptionParagraph.value = data.venueDescription;
      talksDescriptionParagraph.value = data.talksDescription;
      diningDescriptionParagraph.value = data.cuisineDescription;
      facultyDescription.value = data.facultyDescription?.replaceAll("\n", " ");
      talksImageURL.value = data.talksDescriptionImage1;
      detailsSectionImageURL.value = data.talksDescriptionImage2;
      communityDescriptionParagraph.value = data.communityDescription;
      communityImageURL.value = data.communityDescriptionImage;
      cuisineLargeImageURL.value = data.cuisineDescriptionImage1;
      cuisineSmallImageURL.value = data.cuisineDescriptionImage2;
      videoTitle.value = data.recapVideoTitle;
      venueImages.value = data.venueDescriptionImages;
      jumboVideoUrl.value = data.previewVideoUrl;
      showJumboVideo.value = window.innerWidth >= 425;
    })
    .catch((err) => { 
      Sentry.captureException(err);
      window.location.href = "/error";
    });

  fetch( 
    baseApiUrl +
    "/api/singletons/get/njrPreviousRetreatDetails?token=" +
    process.env.VUE_APP_API_TOKEN
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        Sentry.captureException(response);
        window.location.href = "/error";
      }
    })
    .then((data) => {
      albumDetails.value = data;
    })
    .catch((err) => {
      Sentry.captureException(err);
      window.location.href = "/error";
    });

  fetch(
    baseApiUrl +
    "/api/collections/get/njrTestimonials?token=" +
    process.env.VUE_APP_API_TOKEN
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        Sentry.captureException(response);
        window.location.href = "/error";
      }
    })
    .then((data) => {
      testimonialDetails.value = data;
    })
    .catch((err) => {
      Sentry.captureException(err);
      window.location.href = "/error";
    });

  fetch(
    baseApiUrl +
    "/api/collections/get/njrFaculty?token=" +
    process.env.VUE_APP_API_TOKEN
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        Sentry.captureException(response);
        window.location.href = "/error";
      }
    })
    .then((data) => {
      facultyDetails.value = data.entries;
      currentFaculty.value = facultyDetails.value.filter(
        (faculty) => faculty.isCurrent && faculty.isFeatured
      );
    })
    .catch((err) => {
      Sentry.captureException(err);
      window.location.href = "/error";
    });

  fetch(
    baseApiUrl +
    "/api/singletons/get/njrLocationDetails?token=" +
    process.env.VUE_APP_API_TOKEN
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        Sentry.captureException(response);
        window.location.href = "/error";
      }
    })
    .then((data) => {
      locationDetails.value = data.hotelName.split(" <br> ");
    })
    .catch((err) => {
      Sentry.captureException(err);
      window.location.href = "/error";
    });
});

</script>


<template>
  <div>
    <!-- Header Section -->
    <section class="homepage-header">
      <div class="homepage-header__wrapper">
        <div class="homepage-header__video_wrapper">
          <video v-if="showJumboVideo" :src="jumboVideoUrl" muted autoplay loop class="jumbo-video homepage-header__video" @error="hideJumboVideo" :poster="jumboImgUrl">
        </video>
        <div v-if="showJumboVideo" class="homepage-header__video__overlay">
        </div>
        <img v-else :src="jumboImgUrl" alt="" class="homepage-header__video"> 

        </div>
        

        <div class="homepage-header__watch-promo-container">
          <button type="button" class="play-button" data-bs-toggle="modal" data-bs-target="#myModal" @click="playVideo">
            <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.7148 7.42856L0.500558 14.4805L0.500559 0.376641L12.7148 7.42856Z" fill="none" />
            </svg>
          </button>
          <p class="play-button__paragraph">
            <span>Watch </span>Promo
          </p>
        </div>

        <div class="homepage-header__title-image-container"></div>
        <div class="homepage-header__laptop-title-image-container"></div>
        <div class="homepage-header__details-paragraph-container">
          <p class="homepage-header__details-paragraph homepage-header__paragraph1">
            <span>{{ englishDates }}</span>
            {{ hebrewDates }}
          </p>
          <p class="homepage-header__details-paragraph homepage-header__paragraph2">
            <span> {{ locationDetails[0] }}</span>
            {{ locationDetails[1] }}
          </p>
        </div>
        <button type="button" class="homepage-header__button" @click="navigateOnClick('https://njr.myjli.com/')" id="heroRegister">
          Register
          <svg fill="none" viewBox="0 0 46 16">
            <g>
              <path
                d="M36.3008 15.9999C36.3006 14.9396 36.5094 13.8896 36.9151 12.9099C37.3208 11.9302 37.9156 11.04 38.6653 10.2902C39.4151 9.54043 40.3053 8.94569 41.285 8.53997C42.2647 8.13425 43.3147 7.92551 44.3751 7.92566"
                stroke="#B99D6C" />
              <path
                d="M36.3008 -0.14856C36.3017 0.910347 36.5106 1.95876 36.9156 2.93715C37.3201 3.92002 37.9147 4.81331 38.6654 5.56573C39.415 6.31578 40.3051 6.91074 41.2848 7.31658C42.2632 7.72158 43.3116 7.93049 44.3705 7.93144"
                stroke="#B99D6C" />
              <path d="M0.714844 8H45.2863" stroke="#B99D6C" />
            </g>
          </svg>
        </button>

      </div>
    </section>

    <!-- Stats Section -->
    <section class="stat-section">
      <div class="stat-section__wrapper">
        <div class="stat-number">{{ presentersAmount }}</div>
        <div class="stat-description"><span>World Class</span> <span>Presenters</span></div>
        <div class="stat-number">{{ attendeesAmount }}</div>
        <div class="stat-description"><span>Like-Minded</span> <span>Attendees</span></div>
        <div class="stat-number">{{ workshopsAmount }}</div>
        <div class="stat-description"><span>Talks &</span> <span>Workshops</span></div>
      </div>
    </section>

    <!-- Venue Section -->

    <section class="venue-section">
      <div class="venue-section__wrapper">
        <div class="image-slider" :style="{ backgroundImage: 'url(' + baseApiUrl + currentImageSrc + ')' }">

        </div>
        <div class="venue-section__text-container">
          <h2 class="venue-section__title"><span>The</span> <span>Venue</span></h2>
          <h3 class="venue-section__subtitle" >
            <p> {{ locationDetails[0] }}</p>
            {{ locationDetails[1] }}
          </h3>
          <p class="venue-section__text-paragraph">{{venueDescriptionParagraph}}</p>
          <button type="button" class="venue-section__button"
            @click="navigateOnClick('https://jretreat.com/accommodations')">Accommodations
            <svg fill="none" viewBox="0 0 46 16">
              <g>
                <path
                  d="M36.3008 15.9999C36.3006 14.9396 36.5094 13.8896 36.9151 12.9099C37.3208 11.9302 37.9156 11.04 38.6653 10.2902C39.4151 9.54043 40.3053 8.94569 41.285 8.53997C42.2647 8.13425 43.3147 7.92551 44.3751 7.92566"
                  stroke="#B99D6C" />
                <path
                  d="M36.3008 -0.14856C36.3017 0.910347 36.5106 1.95876 36.9156 2.93715C37.3201 3.92002 37.9147 4.81331 38.6654 5.56573C39.415 6.31578 40.3051 6.91074 41.2848 7.31658C42.2632 7.72158 43.3116 7.93049 44.3705 7.93144"
                  stroke="#B99D6C" />
                <path d="M0.714844 8H45.2863" stroke="#B99D6C" />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </section>

    <!-- Details Section -->
    <section class="details-talks">
      <div class="details-talks__wrapper">
        <div class="details-talks__text-container">
          <h2>
            <div class="details-talks__title--v1">Talks &</div><span class="details-talks__title--v2">Workshops</span>
          </h2>
          <p class="details-talks__text-paragraph">{{ talksDescriptionParagraph }}</p>

          <a href="/highlights" class="details-link">Program Highlights</a>
        </div>
        <img :src="baseApiUrl + talksImageURL.path" alt="" class="details-talks__image">
        <img :src="baseApiUrl + detailsSectionImageURL.path" alt="" class="details-photo__image">
      </div>
    </section>

    <section class="details-community">
      <div class="details-community__wrapper">
        <div class="details-community__text-container">
          <h2 class="details-talks__title--v1">Community</h2>
          <p class="details-community__text-paragraph">{{ communityDescriptionParagraph }}</p>
        </div>
        <img :src="baseApiUrl + communityImageURL.path" alt="" class="details-community__image">
      </div>
    </section>

    <section class="details-cuisine">
      <div class="details-cuisine__wrapper">
        <img class="details-cuisine__large-image" :src="baseApiUrl + cuisineLargeImageURL.path" alt="">
        <img class="details-cuisine__small-image" :src="baseApiUrl + cuisineSmallImageURL.path" alt="">
        <div class="details-cuisine__text-section">
          <h2 class="details-talks__title--v1 details-cuisine__title">Cuisine</h2>
          <p class="details-cuisine__text-paragraph">{{ diningDescriptionParagraph }}</p>
        </div>

        <button type="button" class="details-cuisine__btn" @click="navigateOnClick('https://njr.myjli.com/')" id="middleRegisterMobile">Register
          <svg fill="none" viewBox="0 0 46 16">
            <g>
              <path
                d="M36.3008 15.9999C36.3006 14.9396 36.5094 13.8896 36.9151 12.9099C37.3208 11.9302 37.9156 11.04 38.6653 10.2902C39.4151 9.54043 40.3053 8.94569 41.285 8.53997C42.2647 8.13425 43.3147 7.92551 44.3751 7.92566"
                stroke="#B99D6C" />
              <path
                d="M36.3008 -0.14856C36.3017 0.910347 36.5106 1.95876 36.9156 2.93715C37.3201 3.92002 37.9147 4.81331 38.6654 5.56573C39.415 6.31578 40.3051 6.91074 41.2848 7.31658C42.2632 7.72158 43.3116 7.93049 44.3705 7.93144"
                stroke="#B99D6C" />
              <path d="M0.714844 8H45.2863" stroke="#B99D6C" />
            </g>
          </svg>
        </button>
      </div>
    </section>
    <!-- Call To Action Section -->

    <section class="details-call-to-action">
      <div class="details-call-to-action__wrapper">
        <p>{{ registrationTagline }}</p>
        <a class="details-call-to-action__register-section" href="https://njr.myjli.com/" id="middleRegisterDesktop">
          <p>Register</p>
          <img src="../assets/images/white-arrow.svg" alt="">
        </a>
      </div>
    </section>
    <!-- Presenters Section -->

    <div class="presenter-section__wrapper">
      <div class="presenter-section__text-section">
        <h2>
          <div class="presenter-section__title--v1">The</div><span class="presenter-section__title--v2">Presenters</span>
        </h2>
        <p class="presenter-section__text-paragraph">{{ facultyDescription }}</p>
        <a href="/faculty" class="presenter-section__link presenter-section__link1">View All</a>
      </div>
      <div class="presenter-section__slider-section">

        <div class="slider">
          <div class="slider-container" ref="sliderContainer">
            <div class="slider-track" ref="sliderTrack">
              <div class="presenter-card" v-for="(presenter, index) in currentFaculty" :key="index"
                @click="navigateOnClick('https://jretreat.com/faculty')">
                <div class="presenter-card__image-container" :style="calculateBackground(presenter.image.path)">
                  <div class="presenter-card__overlay">
                  </div>
                </div>
                <p class="presenter-card__name">
                  {{ presenter.name }}
                </p>
                <p class="presenter-card__location">
                  {{ presenter.location }}
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="presenter-section__arrow-section">
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="16" viewBox="0 0 45 16" fill="none"
          @click="prevButtonClick">

          <g mask="url(#mask0_184_133)">
            <path
              d="M8.80859 -0.148504C8.80874 0.911868 8.6 1.96188 8.19428 2.94157C7.78856 3.92125 7.19382 4.81142 6.44402 5.56121C5.69423 6.31101 4.80407 6.90575 3.82438 7.31147C2.8447 7.71719 1.79468 7.92593 0.734308 7.92578"
              stroke="#9B845B" />
            <path
              d="M8.80859 16C8.80764 14.9411 8.59874 13.8927 8.19374 12.9143C7.78929 11.9314 7.19467 11.0381 6.44402 10.2857C5.69389 9.53771 4.80382 8.94472 3.82459 8.54057C2.8462 8.13557 1.79779 7.92667 0.738879 7.92571"
              stroke="#9B845B" />
            <path d="M44.043 7.92578L0.000682497 7.92578" stroke="#9B845B" />
          </g>
        </svg>
        <!--  -->
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="16" viewBox="0 0 46 16" fill="none"
          @click="nextButtonClick">

          <g mask="url(#mask0_184_125)">
            <path
              d="M36.1563 16.0001C36.1561 14.9397 36.3648 13.8897 36.7706 12.91C37.1763 11.9303 37.771 11.0401 38.5208 10.2903C39.2706 9.54055 40.1608 8.94581 41.1405 8.54009C42.1201 8.13438 43.1702 7.92563 44.2305 7.92578"
              stroke="#9B845B" />
            <path
              d="M36.1562 -0.148438C36.1572 0.910469 36.3661 1.95888 36.7711 2.93728C37.1756 3.92014 37.7702 4.81343 38.5208 5.56585C39.2704 6.31591 40.1605 6.91086 41.1403 7.3167C42.1186 7.72171 43.1671 7.93061 44.226 7.93156"
              stroke="#9B845B" />
            <path d="M0.570312 8H45.1417" stroke="#9B845B" />
          </g>
        </svg>
      </div>

      <a href="/faculty" class="presenter-section__link presenter-section__link2">View All</a>
    </div>


    <!-- Testimonial Section -->
    <section class="testimonial-section">
      <div class="testimonial">
        <transition-group name="testimonial-slide" tag="div" @before-enter="beforeEnter" @enter="enter" @leave="leave">
          <div :key="currentMessage.name" class="testimonial-message__container">
            <div class="testimonial-message">“{{ currentMessage.message }}”</div>
            <div class="testimonial-author">{{ currentMessage.name }}</div>
          </div>
        </transition-group>
        <div class="circles">
          <span v-for="(quote, index) in testimonialDetails.entries" :key="index"
            :class="{ 'circle': true, 'active': index === currentIndex }"></span>
        </div>
      </div>
    </section>
    <!-- Contact Section -->
    <section class="contact">
      <section class="contact__photo-album">
        <div class="contact__img-container" @click="navigateOnClick(albumDetails?.pastAlbum?.[3].value.link)">
          <div class="contact__img-year">{{ albumDetails?.pastAlbum?.[3].value.tag }}</div>
          <img :src="baseApiUrl + albumDetails?.pastAlbum?.[3].value.image.path" alt="" class="contact__img1">
        </div>
        <div class="contact__img-container" @click="navigateOnClick(albumDetails?.pastAlbum?.[2].value.link)">
          <div class="contact__img-year">{{ albumDetails?.pastAlbum?.[2].value.tag }}</div>
          <img :src="baseApiUrl + albumDetails?.pastAlbum?.[2].value.image.path" alt="" class="contact__img2">
        </div>
        <div class="contact__img-container" @click="navigateOnClick(albumDetails?.pastAlbum?.[1].value.link)">
          <div class="contact__img-year">{{ albumDetails?.pastAlbum?.[1].value.tag }}</div>
          <img :src="baseApiUrl + albumDetails?.pastAlbum?.[1].value.image.path" alt="" class="contact__img3">
        </div>
        <div class="contact__img-container" @click="navigateOnClick(albumDetails?.pastAlbum?.[0].value.link)">
          <div class="contact__img-year">{{ albumDetails?.pastAlbum?.[0].value.tag }}</div>
          <img :src="baseApiUrl + albumDetails?.pastAlbum?.[0].value.image.path" alt="" class="contact__img4">
        </div>
      </section>
      <section class="contact-section">
        <div class="contact-section__wrapper">
          <h2>CONTACT US</h2>
          <p class="contact-section__main-paragraph">For reservations or more information, don’t hesitate to contact us
          </p>

          <div class="contact-section__email-group">
            <p class="contact-section__header">Email</p>
            <p class="contact-section__email"><a href="mailto:info@jretreat.com">info@jretreat.com</a></p>
          </div>
          <div class="contact-section__phone-group">
            <p class="contact-section__header">Telephone</p>
            <p class="contact-section__phone"><a href="tel:+18775738732">1.877.573.8732</a></p>
          </div>
        </div>
      </section>
    </section>
  </div>
  <div class="modal fade remove-padding" id="myModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true" @click="pauseVideo">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="pauseVideo"></button>
        </div>
        <div class="modal-body">
          <iframe width="560" height="315" :src="videoSrc" :title="videoTitle" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen id="recapVideo"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Section - Header */
.homepage-header {
  background: url(../assets/images/header-background.png), var(--color-blue);
  background-blend-mode: luminosity, normal;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 1082px;
}

.homepage-header__wrapper {
  position: relative;
  display: grid;
  grid-template-columns: minmax(128px, 1fr) 1189px minmax(123px, 1fr);
  grid-template-rows: 120px 705px 127px 130px;
}

.homepage-header__video_wrapper {
  position: relative;
  width: 959px;
  height: 685px;
  grid-row: 1 / 2;
  grid-column: 2 / 3; 
  top: 100%;
}


.homepage-header__video {
  /* grid-row: 2 / 3;
  grid-column: 2 / 3; */
  /* width: 959px;
  height: 705px; */
  height: 100%;
  width: 100%;
}
/* .homepage-header__video_wrapper {

} */
.homepage-header__video__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Center horizontally */
  /* align-items: center; Center vertically 
  z-index: -1; */
  background-color:  #39231775; /* transparent*/
}


.homepage-header__watch-promo-container {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  align-self: flex-start;
  justify-self: flex-end;
  margin-right: 55px;
  margin-top: 57px;

}

.homepage-header__title-image-container {
  display: none;
}

.homepage-header__laptop-title-image-container {
  display: block;
  background-image: url(../assets/images/laptop-title.svg);
  background-repeat: no-repeat;
  grid-row: 2/3;
  grid-column: 2/3;
  width: 1189px;
  height: 334px;
  margin: 0;
  align-self: flex-end;
  justify-self: center;
  margin-bottom: 95px;
  transform: translateX(-30px);
   background-size: 75%;
  background-position: 94% 20%; 
}

.homepage-header__details-paragraph-container {
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  display: grid;
  column-gap: 124px;
  grid-template-columns: max-content max-content;
  align-items: flex-end;
}

.homepage-header__details-paragraph {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: var(--color-peach);
}

.homepage-header__details-paragraph span {
  display: block;
}

.homepage-header__button {
  background-color: transparent;
  color: var(--color-gold);
  fill: var(--color-gold);
  text-transform: uppercase;
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.14em;
  border: 0.79834px solid var(--color-gold);
  display: flex;
  flex-direction: row;
  gap: 18px;
  cursor: pointer;

  padding-top: 24px;
  padding-left: 26px;
  padding-right: 24px;
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  align-self: flex-end;
  justify-self: flex-end;
  margin-right: 51px;
  border-radius: 0px !important;
  -webkit-appearance: none;
  appearance: none;

  width: 213px;
  height: 64px;
}

.homepage-header__button:hover {
  color: #FAF6F3;
  background-color: var(--color-gold);
}

.homepage-header__button svg {
  width: 44.57px;
  height: 16px;
}

.homepage-header__button:hover svg path {
  stroke: var(--color-beige);
}

.play-button {
  border-radius: 50%;
  background-color: transparent;
  height: 48px;
  width: 48px;
  border: 1px solid var(--color-gold);
  z-index: 2;
  position: relative;
  cursor: pointer;
}

.play-button svg {
  position: absolute;
  left: 19px;
  bottom: 16px;
}

.play-button svg path {
  fill: var(--color-gold);
  stroke: var(--color-gold);
}

.play-button:hover {
  background-color: var(--color-gold);
  fill: var(--color-beige);
}

.play-button:hover svg path {
  stroke: var(--color-beige);
  fill: var(--color-beige);
}

.play-button__paragraph {
  padding-top: 14px;
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: var(--color-gold);
}

.play-button__paragraph span {
  display: block;
}

/* 1300px */
@media (max-width: 81.25em) {
  .homepage-header {
    height: 576px;
  }

  .homepage-header__wrapper {
    grid-template-rows: 211px 118px 127px 120px;
    grid-template-columns: 1fr 320px 1fr;
  }

.homepage-header__video_wrapper {
  display: contents;
}

.homepage-header__video__overlay {
  display: none;
}

  .homepage-header__video {
    width: 320px;
    height: 210px;
    grid-row: 1/2;
    grid-column: 2/3;
  }

  .homepage-header__watch-promo-container {
    grid-row: 4/5;
    grid-column: 2/3;
    justify-self: flex-start;
    margin: 0;
    display: flex;
    gap: 12px;
    align-items: center;
    align-self: self-start;
    margin-top: 31px;
  }

  .homepage-header__title-image-container {
    display: block;
    background-image: url(../assets/images/mobile-title.svg);
    background-repeat: no-repeat;
    grid-row: 2/3;
    grid-column: 2/3;
    width: 314.51px;
    height: 87.74px;
    margin-top: 28px;
  }

  .homepage-header__laptop-title-image-container {
    display: none;
  }

  .homepage-header__details-paragraph-container {
    grid-row: 3/4;
    grid-column: 2/3;
    column-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    align-items: initial;
    text-align: center;
    justify-items: center;
    padding-top: 21px;
  }

  .homepage-header__paragraph1 {
    align-self: flex-end;
  }

  .homepage-header__paragraph2 {
    align-self: center;
    padding-top: 20px;
  }

  .homepage-header__details-paragraph {
    font-size: 13.506px;
    line-height: 18.007px;
    letter-spacing: 1.621px;
  }

  .homepage-header__button {
    grid-row: 4/5;
    grid-column: 2/3;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 1.4px;
    gap: 11px;
    padding-top: 14px;
    padding-left: 20px;
    padding-right: 10px;
    height: 40px;
    width: 137.5px;
    margin-right: 0;
    align-self: self-start;
    margin-top: 31px;
  }

  .homepage-header__button svg {
    width: 27.857px;
    height: 10px;
  }

  .play-button {
    height: 40px;
    width: 40px;
  }

  .play-button svg {
    left: 15px;
    bottom: 12px;
  }

  .play-button__paragraph {
    padding-top: 0;
    letter-spacing: 1.4px;
    font-size: 10px;
  }

  .play-button__paragraph span {
    display: inline;
  }
}


/* Section - Venue */

.stat-section {
  background-color: var(--color-beige);
  padding-top: 112px;
  padding-bottom: 112px;
}

.stat-section__wrapper {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  color: var(--color-dark-gold);
  text-align: center;
  text-transform: uppercase;
  justify-content: space-evenly;
}

.stat-section__wrapper .stat-number {
  grid-row: 1 / 2;
  font-family: "CanelaLight";
  font-size: 64px;
  font-weight: 300;
  line-height: 64px;
  padding-bottom: 13px;
}

.stat-section__wrapper span:first-child {
  display: block;
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.05em;
  font-family: "GroteskLight";
}

.stat-section__wrapper span:last-child {
  font-family: "GroteskLight";
  font-size: 24px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.05em;
}

/* 1100px */
@media (max-width: 68.75em) {

  .stat-section {
    padding-top: 53px;
    padding-bottom: 52px;
  }

  .stat-section__wrapper {
    grid-template-columns: repeat(2, max-content);
    grid-template-rows: repeat(3, max-content);
    text-align: start;

    justify-content: center;
    column-gap: 24px;
    row-gap: 15px;
  }

  .stat-section__wrapper .stat-description {
    align-self: center;
  }

  .stat-section__wrapper .stat-description:nth-child(4) {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  .stat-section__wrapper .stat-number {
    font-size: 54.8571px;
    line-height: 55px;
    grid-row: auto;
    align-self: center;
    padding-bottom: 0;
  }

  .stat-section__wrapper .stat-number:nth-child(3) {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .stat-section__wrapper span:first-child {
    font-size: 16px;
    line-height: 8px;
  }

  .stat-section__wrapper span:last-child {
    font-size: 16px;
    line-height: 8px;
  }
}

/* Section - Venue */
.venue-section {
  padding-top: 112px;
  padding-bottom: 113px;
  border-top: var(--color-dark-gold) 1px solid;
  background-color: var(--color-beige);
}

.venue-section__wrapper {
  display: grid;
  grid-template-columns: 1fr max-content 475px 1fr;
}

.image-slider {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  width: 800px;
  height: 600px;
  transition: background-image 0.5s;

  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: 9px 0px;
  transform: translateX(-9px);
}

.venue-section__text-container {
  grid-row: 1 / 2;
  grid-column: 3 / 4;
  padding-left: 120px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 88px 129px 103px 128px max-content;
  align-items: flex-start;
  justify-items: flex-start;
}

/* 1440px */
@media (max-width: 90em) {
  .venue-section__wrapper {
    grid-template-columns: max-content 475px 1fr;
  }

  .image-slider {
    grid-column: 1 / 2;
  }

  .venue-section__text-container {
    grid-column: 2 / 3;
  }
}

.venue-section__title {
  grid-row: 2 / 3;
}

.venue-section__title span:first-child {
  font-family: "CanelaThin";
  font-weight: 250;
  font-size: 55px;
  line-height: 55px;
  text-transform: uppercase;
  color: var(--color-dark-gold);
  display: block;
}

.venue-section__title span:last-child {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 63px;
  line-height: 57px;
  text-transform: uppercase;
  color: var(--color-dark-gold);
}

.venue-section__subtitle {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--color-dark-gold);

  grid-row: 3 / 4;
  padding-top: 5px;
}

.venue-section__text-paragraph {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: var(--color-blue);
  width: 380px;

  grid-row: 4 / 5;
}

.venue-section__button {
  background-color: transparent;
  color: var(--color-gold);
  fill: var(--color-gold);
  text-transform: uppercase;
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.14em;
  border: 0.79834px solid var(--color-gold);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 21px;
  cursor: pointer;

  padding: 27px 22.71px;
  width: 295px;
  height: 64px;
  grid-row: 5 / 6;
  border-radius: 0px !important;
  -webkit-appearance: none;
  appearance: none;
}

.venue-section__button svg {
  width: 44.57px;
}

.venue-section__button:hover {
  color: #FAF6F3;
  background-color: var(--color-gold);
}

.venue-section__button:hover svg path {
  stroke: var(--color-beige);
}

/* 1300px */
@media (max-width: 81.25em) {
  .venue-section {
    padding-top: 48px;
    padding-bottom: 49px;
  }

  .venue-section__wrapper {
    grid-template-columns: 1fr 320px 1fr;
    grid-template-rows: max-content max-content;
    row-gap: 41px;
  }

  .image-slider {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    width: 500px;
    height: 300px;
    background-position: 6px 0px;
    transform: translateX(-6px);
  }

  .venue-section__text-container {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    padding-left: 0;
    grid-template-rows: 129px 103px 128px max-content;
  }

  .venue-section__title {
    grid-row: 1 / 2;
  }

  .venue-section__subtitle {
    grid-row: 2 / 3;
  }

  .venue-section__text-paragraph {
    grid-row: 3 / 4;
  }

  .venue-section__button {
    grid-row: 4 / 5;
  }
}

/* 800px */
@media (max-width: 50em) {
  .image-slider {
    width: 320px;
    height: 208px;
    background-position: 4px 0px;
    transform: translateX(-4px);
  }

  .venue-section__text-container {
    grid-template-rows: 93px 60px 96px max-content;
  }

  .venue-section__title span:first-child {
    font-size: 33px;
    line-height: 33px;
  }

  .venue-section__title span:last-child {
    font-size: 38px;
    line-height: 34px;
  }

  .venue-section__subtitle {
    font-size: 16px;
    line-height: 17.714px;
  }

  .venue-section__text-paragraph {
    font-size: 16px;
    line-height: 24px;
    width: 320px;
  }

  .venue-section__button {
    width: 185px;
    height: 40px;
    padding: 15px;
    gap: 13.125px;
    border: 0.7px solid #9B845B;
    color: #9B845B;
    font-size: 10px;
    letter-spacing: 1.4px;
  }

  .venue-section__button svg {
    width: 27.857px;
  }
}

/* Section - Presenters */

.presenter-section__slider-section {
  grid-row: 1/2;
  grid-column: 3/4;
  margin-block-start: 146px;
  overflow-x: hidden;
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-section__slider-section {
    grid-row: 2/3;
    grid-column: 2/3;
    margin-block-start: 42px;
  }
}

.slider {
  /* width should be the width of the image plus padding right times three */
  width: 678px;
  overflow: hidden;
}

/* 1300px */
@media (max-width: 81.25em) {
  .slider {
    width: 452px;
  }
}

/* 1000px */
@media (max-width: 62.5em) {
  .slider {
    width: 386px;
  }
}

.slider-container {
  display: flex;
}

.slider-track {
  display: flex;
  transition: transform 0.3s ease-in-out;
}

.presenter-section__wrapper {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: 1fr 480px 647px 1fr;

  background-color: var(--color-beige);
  padding-top: 197px;
  padding-bottom: 139px;
}

/* 1300px */
@media (max-width: 81.25em) {
  .presenter-section__wrapper {
    grid-template-columns: 1fr 480px 421px 1fr;
  }
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-section__wrapper {
    grid-template-columns: 1fr 369px 1fr;
    grid-template-rows: max-content max-content 39px 50px;
    padding-top: 65px;
    padding-bottom: 43px;
  }
}

/* 500px */
@media (max-width: 31.25em) {
  .presenter-section__wrapper {
    grid-template-columns: 1fr 369px;
    grid-template-rows: max-content max-content 39px 50px;
    padding-top: 65px;
    padding-bottom: 43px;
  }
}

.presenter-section__text-section {
  grid-column: 2 / 3;
  grid-row: 1/2;
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-section__text-section {
    justify-self: start;
  }
}

.presenter-section__text-paragraph {
  width: 355px;
  padding-top: 25px;
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-section__text-paragraph {
    padding-top: 13px;
    width: 310px;
  }
}

.presenter-section__arrow-section {
  display: flex;
  gap: 16px;
  grid-row: 1/2;
  grid-column: 3/4;
  align-self: end;
  transform: translateY(21px);
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-section__arrow-section {
    grid-row: 3/4;
    grid-column: 2/3;
    padding-top: 10px;
    gap: 11px;
    transform: translateY(0);
  }
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-section__arrow-section svg {
    width: 29.5px;
  }
}

.presenter-section__arrow-section svg:hover {
  cursor: pointer;
}

.presenter-section__arrow-section svg:hover path {
  stroke: var(--color-grey);
  cursor: pointer;
}

.presenter-card__image-container {
  position: relative;
  display: inline-block;
  background-blend-mode: LUMINOSITY, NORMAL;
  width: 195px;
  height: 195px;
  background-size: contain;
  cursor: pointer;
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-card__image-container {
    width: 176px;
    height: 176px;
  }
}

.presenter-card__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(23, 36, 75, 0.2);
  opacity: 0;
}

.presenter-card__overlay:hover {
  opacity: 1;
}

.presenter-card {
  padding-right: 31px;
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-card {
    padding-right: 17px;
  }
}

/* Typography */

.presenter-section__title--v1 {
  font-family: "CanelaThin";
  font-weight: 250;
  font-size: 55px;
  line-height: 55px;
  text-transform: uppercase;
  color: var(--color-dark-gold);
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-section__title--v1 {
    font-size: 33px;
    line-height: 28px;
  }
}

.presenter-section__title--v2 {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 63px;
  line-height: 53px;
  text-transform: uppercase;
  color: var(--color-dark-gold);
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-section__title--v2 {
    font-size: 38.6524px;
    line-height: 42px;
  }
}

.presenter-section__text-paragraph {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: var(--color-blue);
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-section__text-paragraph {
    font-size: 16px;
    line-height: 24px;
  }
}

.presenter-section__link {
  text-decoration: none;
  position: relative;
  width: max-content;
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--color-dark-gold);
  display: block;
  padding-top: 39px;
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-section__link {
    padding-top: 39px;
    grid-row: 4 / 5;
    grid-column: 2 / 3;
    font-size: 13.033px;
    line-height: 19.549px;
  }
}

.presenter-section__link:hover {
  color: var(--color-grey);
}

.presenter-section__link::after {
  content: "";
  height: 1px;
  background-color: var(--color-dark-gold);
  display: block;
  width: 100%;
  position: absolute;
  bottom: -6px;
  left: 0;
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-section__link::after {
    bottom: -2px;
    height: .65px;
  }
}

.presenter-section__link:hover::after {
  background-color: var(--color-grey);
}

.presenter-card__name {
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--color-blue);
  margin-block-start: 12px;
  margin-block-end: 1px;
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-card__name {
    font-size: 12px;
    line-height: 12px;
    margin-block-start: 7px;
  }
}

.presenter-card__location {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--color-blue);
  margin-block-start: 0;
  margin-block-end: 0;
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-card__location {
    font-size: 12px;
    line-height: 16px;
  }
}

/* Display */
.presenter-section__link2 {
  display: none;
}

/* 1000px */
@media (max-width: 62.5em) {
  .presenter-section__link1 {
    display: none;
  }

  .presenter-section__link2 {
    display: block;
    padding-top: 0;
    align-self: end;
  }
}

/* 345px */
@media (max-width: 21.5625em) {
  .presenter-section__text-paragraph {
    font-size: 14px;
    line-height: 24px;
    width: 270px;
  }
}

/* Section - Details */
.details-cuisine {
  padding-bottom: 168px;
}

.details-cuisine__wrapper {
  display: grid;
  grid-template-columns: 1fr max-content 479px 1fr;
}

.details-cuisine__large-image {
  width: 646px;
  height: 792px;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.details-cuisine__small-image {
  width: 359px;
  height: 230px;
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  justify-self: end;
  align-self: center;
  margin-bottom: 101px;
}

.details-cuisine__text-section {
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  justify-self: end;
  align-self: flex-end;
}

.details-cuisine__text-paragraph {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: var(--color-blue);

  width: 360px;
  padding-top: 17px;
  padding-bottom: 28px;
}

.details-cuisine__btn {
  background-color: transparent;
  color: var(--color-gold);
  fill: var(--color-gold);
  text-transform: uppercase;
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.14em;
  border: 0.79834px solid var(--color-gold);
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 13.13px;
  cursor: pointer;

  border-radius: 0px !important;
  -webkit-appearance: none;
  appearance: none;
}

.details-cuisine__btn:hover {
  color: #FAF6F3;
  background-color: var(--color-gold);
}

.details-cuisine__btn:hover svg path {
  stroke: var(--color-beige);
}

/* Section - Details Community */
.details-community {
  padding-top: 31px;
  padding-bottom: 201px;
}

.details-community__wrapper {
  display: grid;
  grid-template-columns: 1fr 480px max-content 1fr;
}

.details-community__text-container {
  grid-column: 2 / 3;
  align-self: end;
  padding-bottom: 49px;
}

.details-community__text-paragraph {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: var(--color-blue);

  padding-top: 21px;
  width: 338px;

}

.details-community__image {
  grid-column: 3 / 4;
  width: 707px;
  height: 438px;
}


/* Section - Details Photo */

.details-photo__image {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
  width: 262px;
  height: 370px;
  margin-top: 138px;
  margin-left: 97px;
}

/* Section - Details Talks */
.details-talks__text-container {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  align-self: center;
}

.details-talks {
  padding-top: 209px;
}

.details-talks__wrapper {
  display: grid;
  grid-template-columns: 1fr 577px max-content 1fr;
}

.details-talks__title--v1 {
  font-family: "CanelaThin";
  font-weight: 250;
  font-size: 55px;
  line-height: 55px;
  text-transform: uppercase;
  color: var(--color-dark-gold);
}

.details-talks__title--v2 {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 63px;
  line-height: 53px;
  text-transform: uppercase;
  color: var(--color-dark-gold);
}

.details-talks__text-paragraph {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.01em;
  color: var(--color-blue);

  padding-bottom: 40px;
  padding-top: 21px;
  width: 374px;
}

.details-link {
  text-decoration: none;
  position: relative;
  width: max-content;
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--color-dark-gold);
}

.details-link::after {
  content: "";
  height: 1px;
  background-color: var(--color-dark-gold);
  display: block;
  width: 100%;
  position: absolute;
  bottom: -6px;
  left: 0;
}

.details-link:hover {
  color: var(--color-grey);
}

.details-link:hover::after {
  background-color: var(--color-grey);
}

.details-talks__image {
  grid-row: 1 / 2;
  grid-column: 3 / 4;
  width: 708px;
  height: 520px;
}

/* 1440px */
@media (max-width: 90em) {
  .details-talks__wrapper {
    grid-template-columns: 1fr 577px max-content;
  }

  .details-community__wrapper {
    grid-template-columns: 1fr 480px max-content;
  }

}

/* 1300px */
@media (max-width: 81.25em) {
  .details-talks__wrapper {
    grid-template-columns: minmax(34px, 1fr) max-content minmax(37px, 1fr);
  }

  .details-talks__text-container {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    padding-bottom: 48px;
  }

  .details-talks__image {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .details-photo__image {
    grid-row: 3/ 4;
    margin-top: 0;
    margin-left: 0;
    justify-self: end;
    transform: translateY(-65%) translateX(55%);
  }

  .details-community {
    padding-top: 0;
    transform: translateY(-204px);
    padding-bottom: 0;
  }

  .details-community__wrapper {
    grid-template-columns: minmax(35px, 1fr) max-content minmax(35px, 1fr);
  }

  .details-community__text-container {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .details-community__image {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .details-community__text-paragraph {
    width: 400px;
  }

}

/* 1200px */
@media (max-width: 75em) {
  .details-cuisine__wrapper {
    grid-template-columns: 1fr max-content 425px 1fr;
  }
}

/* 1100px */
@media (max-width: 68.75em) {

  .details-talks {
    padding-top: 71px;
  }

  .details-talks__image {
    width: 255.969px;
    height: 188px;
  }

  .details-photo__image {
    width: 107px;
    height: 152px;
    transform: translateY(-50%) translateX(0%);
  }

  .details-talks__title--v1 {
    font-size: 33px;
    line-height: 33px;
  }

  .details-talks__title--v2 {
    font-size: 38.6524px;
    line-height: 38px;
  }

  .details-talks__text-paragraph {
    font-size: 16px;
    line-height: 24px;
    width: 320px;
    padding-top: 15px;
    padding-bottom: 17px;
  }

  .details-link {
    font-size: 13px;
    line-height: 20px;
  }

  .details-community__text-paragraph {
    font-size: 16px;
    line-height: 24px;
    width: 316px;
    padding-top: 16px;
  }

  .details-community__image {
    width: 320px;
    height: 200px;
  }

  .details-community__text-container {
    padding-bottom: 38px;
  }

  .details-community {
    transform: translateY(-40px);
  }

  .details-cuisine {
    padding-bottom: 24px;
    transform: translateY(-11px);

  }

  .details-cuisine__wrapper {
    grid-template-columns: minmax(35px, 1fr) max-content minmax(35px, 1fr);
  }

  .details-cuisine__large-image {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    width: 103px;
    height: 128px;
    transform: translateX(-30%) translateY(-13%);
    justify-self: end;
  }

  .details-cuisine__small-image {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    width: 256px;
    height: 161px;
    justify-self: start;
    margin-bottom: 0;
  }

  .details-cuisine__text-section {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    /* justify-self: end;
    align-self: flex-end; */
  }

  .details-cuisine__text-paragraph {
    font-size: 16px;
    line-height: 24px;

    width: 320px;
    padding-top: 19px;
    padding-bottom: 36px;
  }

  .details-cuisine__btn {
    display: flex;
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    justify-self: start;
    align-self: end;
    font-size: 10px;
    line-height: 13px;
    padding: 13px 18px;
    margin-bottom: 17px;
  }

  .details-cuisine__btn svg {
    width: 27.86px;
  }

}

/* 375px */
@media (max-width: 23.4375em) {
  .details-community__text-paragraph {
    font-size: 14px;
    line-height: 24px;
    width: 280px;
  }

  .details-cuisine__text-paragraph {
    font-size: 14px;
    line-height: 24px;
    width: 290px;
  }

  .details-talks__text-paragraph {
    font-size: 14px;
    line-height: 24px;
    width: 280px;
  }

  .venue-section__text-paragraph {
    font-size: 14px;
    line-height: 24px;
    width: 280px;
  }
}

.details-call-to-action {
  display: grid;
  grid-template-columns: 1fr 1127px 1fr;
}

/* 1300px */
@media (max-width: 81.25em) {
  .details-call-to-action {
    grid-template-columns: 1fr 901px 1fr;
  }
}

/* 1100px */
@media (max-width: 68.75em) {
  .details-call-to-action {
    display: none;
  }
}

.details-call-to-action p {
  color: var(--color-footer-text-secondary);
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 2.24px;
  text-transform: uppercase;
  padding-top: 1px;
}

.details-call-to-action__register-section {
  display: flex;
  gap: 15px;
  position: relative;
  text-decoration: none;
}

.details-call-to-action__register-section::before {
  content: "";
  height: 1px;
  background: var(--color-footer-text-secondary);
  display: block;
  width: 100%;
  position: absolute;
  left: -75%;
  transform: rotate(-90deg);
}

.details-call-to-action__wrapper {
  background-color: var(--color-gold);
  display: flex;
  justify-content: space-between;
  padding: 30px 40px;
  transform: translateY(50%);
  grid-column: 2 / 3;
}

/* Section - General */
.remove-padding {
  padding-right: 0 !important;
}

img,
svg {
  vertical-align: initial;
}

ul,
h2,
h3,
p {
  margin: 0;
  padding: 0;
}

.second-grid-container {
  display: grid;
  grid-template-columns: [full-start] minmax(10.76%, 1fr) [center-start] repeat(11, minmax(6.68%, min-content)) 5% [center-end] minmax(10.76%, 1fr) [full-end];
}

@media (max-width: 25em) {
  .second-grid-container {
    grid-template-columns: [full-start] minmax(35px, 1fr) [center-start] repeat(3, minmax(86px, min-content)) 62px [center-end] minmax(35px, 1fr) [full-end];
  }
}


/* Section - Contact */

.contact {
  background-color: var(--color-navy);
  border-bottom: var(--color-gold) 1px solid;
}

.contact-section__email-group {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.contact-section__phone-group {
  grid-row: 2 / 3;
  grid-column: 3 / 4;
}

.contact-section {
  background-color: var(--color-navy);
  padding-top: 90px;
  padding-bottom: 100px;
}

.contact-section__wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1128px;
  display: grid;
  grid-template-columns: 228px 576px 1fr;
  grid-template-rows: 166px 1fr;
  align-items: flex-start;
}

.contact-section h2 {
  font-family: "GroteskRegular";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--color-grey);
  margin: 0;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  margin-top: 10px;
}

.contact-section__main-paragraph {
  font-family: "CanelaLight";
  font-weight: 300;
  font-size: 48px;
  line-height: 64px;
  color: var(--color-gold);
  width: 720px;
  margin: 0;
  grid-row: 1 / 2;
  grid-column: 2 / 3;
}

.contact-section__header {
  font-family: "GroteskRegular";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--color-grey);
  margin-top: 0;
  margin-bottom: 10px;
}

.contact-section__email {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 36px;
  line-height: 46px;
  color: var(--color-peach);
  position: relative;
  width: max-content;
  margin: 0;
}

.contact-section__email::after {
  content: "";
  height: 1px;
  background: var(--color-gold);
  display: block;
  width: 100%;
  position: absolute;
  bottom: 5px;
  left: 0;
}

.contact-section__phone {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 36px;
  line-height: 46px;
  color: var(--color-peach);
  margin: 0;
}

.contact-section__phone a,
.contact-section__email a {
  color: inherit;
  text-decoration: none;
}

/* 1200px */
@media (max-width: 75em) {
  .contact-section__email-group {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }

  .contact-section__phone-group {
    grid-row: 2 / 3;
    grid-column: 3 / 4;
  }

  .contact-section {
    padding-top: 42px;
    padding-bottom: 43px;
  }

  .contact-section__wrapper {
    grid-template-columns: minmax(35px, 1fr) max-content minmax(97px, 1fr);
    grid-template-rows: initial;
    margin-right: 0;
    margin-left: 0;
    max-width: initial;
    align-items: initial;
  }

  .contact-section h2 {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    margin-top: 0;
    font-size: 13px;
    line-height: 30px;
  }

  .contact-section__main-paragraph {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    font-size: 24px;
    line-height: 34.714px;
    width: 276px;
    margin-top: 6px;
  }

  .contact-section__header {
    font-size: 12px;
    line-height: 18.714px;
    margin-bottom: 0;
  }

  .contact-section__email-group {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    margin-top: 22px;
  }

  .contact-section__email {
    font-size: 20px;
  }

  .contact-section__email::after {
    height: 0.624px;
    bottom: 7px;
  }

  .contact-section__phone-group {
    grid-row: 4 / 5;
    grid-column: 2 / 3;
    margin-top: 23px;
  }

  .contact-section__phone {
    font-size: 20px;
  }
}

.contact__photo-album {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 37.5em) {
  .contact__photo-album {
    grid-template-columns: repeat(2, 1fr);
  }
}

.contact__img1 {
  width: 100%;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  z-index: 1;
}

.contact__img2,
.contact__img3,
.contact__img4 {
  opacity: 60%;
  width: 100%;
  grid-row: 1 / 2;
  grid-column: 1 / -1;
  z-index: 1;
}

.contact__img1:hover,
.contact__img2:hover,
.contact__img3:hover,
.contact__img4:hover {
  opacity: 25%;
}

.contact__img-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  cursor: pointer;
}

.contact__img-year {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  z-index: 2;
  align-self: end;
  margin: 20px;
  font-family: "GroteskSemiBold";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: var(--color-peach);
  cursor: pointer;
}

@media (max-width: 75em) {
  .contact__img-year {
    margin: 16px;
  }
}

@media (max-width: 37.5em) {
  .contact__img-year {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (max-width: 31.25em) {
  .contact__img-year {
    font-size: 14.1518px;
    line-height: 18px;
  }
}

/* Section - Testimonial */
.testimonial-section {
  padding-top: 113px;
  padding-bottom: 93px;
  background-color: #B99D6C;
  display: grid;
  align-items: center;
  justify-items: center;
}

.testimonial-message {
  font-family: "CanelaLight";
  font-weight: 300;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: var(--color-light);
  padding-bottom: 27px;

}

.testimonial-author {
  font-family: "GroteskRegular";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: var(--color-light);
  padding-bottom: 27px;
  text-align: center;
}

.testimonial-message__container {
  width: 880px;
}

.testimonial {
  position: relative;
  height: 250px;
  overflow: hidden;
}

.testimonial-slide-enter-active,
.testimonial-slide-leave-active {
  transition: transform 1s;
}

.testimonial-slide-enter,
.testimonial-slide-leave-to {
  position: absolute;
}

.circles {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #9B845B;
  margin: 0 4px;
  background-color: #9B845B;
}

.active {
  background-color: transparent;
  border-color: #F5E7DA;
}

/* 1100px */
@media (max-width: 68.75em) {
  .testimonial-section {
    padding-top: 58.33px;
    padding-bottom: 19px;
  }

  .testimonial-message {
    font-size: 26.036px;
    line-height: 34.714px;
    padding-bottom: 13px;
  }

  .testimonial-author {
    font-size: 10.848px;
    line-height: 16.272px;
    padding-bottom: 13px;
  }

  .testimonial-message__container {
    width: 300px;
  }

  .testimonial {
    height: 250px;
  }
}

/* Section - Previous Code */

.modal-content {
  background-color: transparent;
  border: none;
}

.modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}

.btn-close {
  filter: invert(89%) sepia(3%) saturate(982%) hue-rotate(324deg) brightness(113%) contrast(97%);
}

.modal-header .btn-close {
  margin: -0.5rem -0.25rem -0.95rem auto;
}

.modal-dialog {
  max-width: 595px;
}

*:focus {
  outline: 0 !important;
}
</style>



