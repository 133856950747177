<script>
import * as Sentry from "@sentry/vue";

export default {
  name: "AccommodationsPage",
  data() {
    return {
      locationDetails: {},

      imagePaths: {
        accommodationImage1Path: "",
        accommodationImage2Path: "",
      },
    };
  },
  mounted() {
    fetch(
      this.$baseApiUrl +
        "/api/singletons/get/njrLocationDetails?token=" +
        process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = '/error';
        }
      })
      .then((data) => {
        this.locationDetails = data;
        this.imagePaths.accommodationImage1Path = this.$baseApiUrl + data.accommodationImage1.path;
        this.imagePaths.accommodationImage2Path = this.$baseApiUrl + data.accommodationImage2.path;
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = '/error';
      });
      fetch( this.$baseApiUrl +
        "/api/singletons/get/njrLocationDetails?token=" +
        process.env.VUE_APP_API_TOKEN)
  },
};
</script>


<template>
  <div class="pageContainer">
    <div>
      <header>
        <div class="accomodationHeaderBkgd">
          <div class="row rowAcom">
            <div class="col-6">
              <div class="pic">
                <img
                  :src="imagePaths.accommodationImage1Path"
                  alt=""
                  class="img-fluid"
                />
                <div class="overlay"></div>
              </div>
            </div>

            <div class="col-6">
              <div class="pic">
                <img
                  :src="imagePaths.accommodationImage2Path"
                  alt=""
                  class="img-fluid"
                />
                <div class="overlay"></div>
              </div>
            </div>
          </div>
          <div class="wrapper">
            <div class="spaceOut">
              <div class="row">
                <div class="col-md-6">
                  <h1 class="goldH1">Hotel and Transportation</h1>
                </div>
                <div class="col-md-6">
                  <div class="mediumBlue">
                    <p class="move" v-html="locationDetails.hotelName"></p>
                  </div>
                  <div class="lightBlue">
                    <p v-html="locationDetails.hotelAddress"></p>

                    <!-- <p>{{ locationDetails.hotelAddress }}</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="mainSpace">
        <!-- <div> -->
        <div class="pageInfo">
          <div class="accommodationContent">
            <div class="wrapper">
              <div class="pageSec">
                <div class="hotelInfo">
                  <div class="row">
                    <div class="col-md-4">
                      <div>
                        <h2 class="goldenH2">Hotel</h2>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="lightBlueP">
                        <p v-html="locationDetails.hotelDetails"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pageSec">
                <div class="airportInfo">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="goldenH2">
                        <h2>Airport</h2>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="lightBlueP">
                        <p v-html="locationDetails.airportDetails"></p>
                        <!-- <p>Estimated taxi fare: {{ taxiFare }}</p>
                          <p>Airport Shuttle:{{ shuttleInfo }}</p>

                          <p>Driving Directions: {{ drivingDirections }}</p> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pageSec">
                <div class="rentalInfo">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="goldenH2">
                        <h2>Car Rental</h2>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <!-- <div class="regularBlue">
                          <p>{{ rentalCompanyName }}</p>
                        </div> -->
                      <div class="lightBlueP">
                        <p v-html="locationDetails.carRentalDetails"></p>
                        <!-- <p>{{ rentalAdress }}</p>
                          <p>{{ rentalNumber }}</p> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="bottomSection">
                <div class="parkingInfo">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="goldenH2">
                        <h2>Parking</h2>
                      </div>
                    </div>
                    <div class="col-md-8">
                      <!-- <div class="regularBlue">
                                            <p>Self-Parking</p>
                                        </div> -->
                      <div class="lightBlueP">
                        <p v-html="locationDetails.parkingDetails"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>


<style scoped>
.goldenH2 {
  color: #b79b6e;
  font-family: "CanelaRegular";
}
.row.rowAcom {
  --bs-gutter-x: 0rem;
}
.bottomSection {
  margin-top: 50px;
}
.move {
  margin-bottom: 0em;
}
.lightBlue {
  margin-top: 5px;
}
.pic {
  position: relative;
}
.overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #17244b;
  opacity: 0.1;
}
@media (max-width: 920px) {
  .goldH1 {
    font-size: 6.3vw;
  }
}
@media (max-width: 575.98px) {
  .spaceOut {
    padding: 50px 0px;
    width: 60%;
  }
  .goldH1 {
    font-size: 8vw;
  }

  .mediumBlue {
    font-size: 4vw;
  }
  .lightBlue {
    font-size: 4vw;
  }
}
@media (max-width: 459px) {
  .goldH1 {
    font-size: 9vw;
  }
}
</style>