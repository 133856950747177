<template>
  <h1>SinaiScholarsPage</h1>
</template>

<script>
export default {
  name: "SinaiScholarsPage",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
