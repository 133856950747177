<template>
  <div class="navbar-container">
    <header :class="{ 'site-navbar': true, 'site-navbar--blue': !isScrolled, 'site-navbar--beige': isScrolled }"
      id="nav-menu">
      <div class="site-navbar__icon-section">
        <div
          :class="{ 'site-navbar--regular-image': calculateRegularImage(), 'site-navbar--gold-image': calculateGoldImage(), 'navbar-icon': true }"
          @click="navigateOnClick('https://jretreat.com/')"></div>
      </div>
      <nav>
        <ul
          :class="{ 'text-navbar-main': true, 'text-navbar-main--peach': calculateIfPeach(), 'text-navbar-main--navy': calculateIfNavy() }">
          <li class="custom-navbar-item"><a href="#">Program</a>
            <ul
              :class="{ 'custom-dropdown': true, 'site-navbar--blue': calculateIfPeach(), 'site-navbar--beige': calculateIfNavy() }">
              <li class="custom-dropdown-item">
                <a class="custom-dropdown-link" href="faculty" @click="hideMenu">THE PRESENTERS</a>
              </li>
              <li class="custom-dropdown-item">
                <a class="custom-dropdown-link" href="childrens_program" @click="hideMenu">CHILDREN'S PROGRAM</a>
              </li>
              <li class="custom-dropdown-item">
                <a class="custom-dropdown-link" href="schedule" @click="hideMenu">SCHEDULE</a>
              </li>
              <!-- <li class="custom-dropdown-item">
                <a class="custom-dropdown-link" href="wellness_institute" @click="hideMenu">WELLNESS SUMMIT</a>
              </li> -->
              <li class="custom-dropdown-item">
                <a class="custom-dropdown-link"
                  href="https://www.myjli.com/catalog/browse-by-topics/audio-collections/">AUDIO
                  COLLECTIONS</a>
              </li>
            </ul>
          </li>
          <li class="custom-navbar-item"><a href="highlights">Highlights</a></li>
          <li class="custom-navbar-item"><a href="accommodations">The Venue</a></li>
          <li class="custom-navbar-item"><a href="pricing">Pricing</a></li>
          <li class="custom-navbar-item"><a href="contact">Contact</a></li>
          <li class="custom-navbar-item"><a href="faq">Faq</a></li>
          <li class="custom-navbar-item text-navbar-register"><a href="https://myjli.com/retreat/" id="navbarRegister">Register</a></li>
        </ul>
      </nav>
      <img src="../assets/images/mobile-navbar-icon.png" alt="" class="site-navbar__menu-icon" @click="toggleMenu">
    </header>
  </div>
</template>

<script>

export default {
  name: "NavBar",
  data() {
    return {
      isScrolled: false,
      isMobile: false,
    };
  },
  methods: {
    hideMenu() {
      document.getElementById("nav-menu").classList.remove("show");
      this.isMobile = false;
    },
    toggleMenu() {
      document.getElementById("nav-menu").classList.toggle("show");
      this.isMobile = document.getElementById("nav-menu").classList.contains("show");
    },
    navigateOnClick(url) {
      window.location.href = url;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
      this.isMobile = document.getElementById("nav-menu").classList.contains("show");
    },
    calculateRegularImage() {
      if (this.isScrolled && !this.isMobile)
        return false;
      return true;
    },
    calculateGoldImage() {
      if (this.isScrolled && !this.isMobile)
        return true;
      return false;
    },
    calculateIfNavy() {
      if (!this.isScrolled && !this.isMobile || this.isScrolled && this.isMobile || !this.isScrolled && this.isMobile)
        return false;
      return true;
    },
    calculateIfPeach() {
      if (this.isScrolled && !this.isMobile)
        return false;
      return true;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    const width = window.innerWidth;
    this.isMobile = width <= 1000;
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};



</script>

<style scoped>
.site-navbar--gold-image {
  background-image: url("../assets/images/navbar-icon-gold.svg");
}

.site-navbar--regular-image {
  background-image: url("../assets/images/navbar-icon.svg");
}

.site-navbar--blue {
  background-color: var(--color-blue);
}

.site-navbar--beige {
  background-color: var(--color-beige);
}

.navbar-icon {
  height: 56px;
  width: 115px;
}

#nav-menu.show {
  background-color: var(--color-blue);
  width: 100%;
  height: 100vh;
  grid-template-rows: max-content 1fr;
}


nav ul li:hover>.custom-dropdown {
  display: block;
}

.custom-dropdown {
  display: none;
  position: absolute;
  transform: translateX(-13%) translateY(2px);
}

.site-navbar.show .custom-dropdown {
  position: static;
  background-color: var(--color-blue);
  transform: translateX(0) translateY(0);
  width: 110%;
}

.custom-dropdown .custom-dropdown-item {
  display: block;
  padding-bottom: 15px;
  padding-left: 25px;
  padding-right: 25px;
}

.custom-dropdown .custom-dropdown-item:first-child {
  padding-top: 15px;
}

.site-navbar.show .custom-dropdown-item {
  padding-bottom: 0;
  padding-left: 33px;
  padding-top: 0;
}

.site-navbar.show .custom-dropdown-link {
  font-size: 3vw;
  letter-spacing: .1em;
}

@media (max-width: 31.25em) {
  .site-navbar.show .custom-dropdown-link {
    font-size: 20px;
  }
}

.site-navbar {

  border-bottom: var(--color-gold) 0.75px solid;
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-auto-flow: column;
}

@media (max-width: 62.5em) {
  .site-navbar {
    border-bottom: none;
    justify-content: center;
  }
}

.site-navbar__icon-section {
  padding-left: 68px;
}

.site-navbar.show .site-navbar__icon-section {
  padding-left: 0;
  justify-self: center;
  padding-top: 30px;
  padding-bottom: 40px;
}

.site-navbar__icon-section .navbar-icon {
  cursor: pointer;
}

@media (max-width: 62.5em) {
  .site-navbar__icon-section {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 120;
  }
}

@media (max-width: 25em) {
  .site-navbar__icon-section .navbar-icon {
    width: 81.46px;
  }
}

.site-navbar__icon-section::after {
  content: "";
  height: .75px;
  background: var(--color-gold);
  display: block;
  width: 165%;
  transform: rotate(-90deg) translateY(90px) translateX(60px);
}

@media (max-width: 62.5em) {
  .site-navbar__icon-section::after {
    display: none;
  }
}

.site-navbar nav {
  width: 914px;
  padding-right: 2%;
}

@media (max-width: 90em) {
  .site-navbar nav {
    padding-right: 0;
  }
}

@media (max-width: 81.25em) {
  .site-navbar nav {
    width: 850px;
  }
}

@media (max-width: 75em) {
  .site-navbar nav {
    width: 700px;
  }
}

@media (max-width: 62.5em) {
  .site-navbar nav {
    display: none;
  }

  .site-navbar.show nav {
    display: block;
    width: 100%;
    align-self: start;
    padding-left: 10%;
  }
}

@media (max-width: 50em) {
  .site-navbar.show nav {
    justify-self: center
  }
}

.site-navbar .text-navbar-main {
  list-style: none;
  display: grid;
  grid-auto-flow: column;
}

.site-navbar.show .text-navbar-main {
  grid-auto-flow: row;
}

.site-navbar.show .custom-navbar-item {
  font-size: 5vw;
  line-height: 9vw;
}

@media (max-width: 31.25em) {
  .site-navbar.show .custom-navbar-item {
    font-size: 30px;
    line-height: 60px;
  }
}

.site-navbar li {
  display: inline-block;
}

.site-navbar a {
  color: inherit;
  text-decoration: none;
  display: block;
}

.site-navbar a:hover {
  color: var(--color-grey);
}

.site-navbar__menu-icon {
  display: none;
}

@media (max-width: 62.5em) {
  .site-navbar__menu-icon {
    display: block;
    position: absolute;
    right: 50px;
    cursor: pointer;
    top: 35px;
  }
}

@media (max-width: 50em) {
  .site-navbar__menu-icon {
    right: 35.15px;
  }
}

.text-navbar-main--peach {
  color: var(--color-peach);
}

.text-navbar-main--navy {
  color: var(--color-navy);
}

.text-navbar-main {
  font-family: "GroteskRegular";
  font-weight: 400;
  font-size: 12.75px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.06em;

}

@media (max-width: 75em) {
  .text-navbar-main {
    font-size: 10.2px;
    line-height: 12.8px;
  }
}

.text-navbar-register {
  font-family: "GroteskSemiBold";
  font-weight: 600;
  font-size: 14.1592px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: var(--color-gold);
}

@media (max-width: 75em) {
  .text-navbar-register {
    font-size: 11.3274px;
    line-height: 14.4px;
  }
}

.navbar-container {
  display: grid;
  grid-template-rows: 126.75px;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 100;
}

/* 1000px */
@media (max-width: 62.5em) {
  .navbar-container {
    grid-template-rows: 96px;
  }
}

/* 800px */
@media (max-width: 50em) {
  .navbar-icon {
    height: 45px;
    width: 82px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

img {
  vertical-align: initial;
}

ul {
  margin: 0;
  padding: 0;
}
</style>
