<template>
  <div class="pageContainer">
    <header>
      <div class="bh">ב"ה</div>
      <div class="logoMobile">
        <a class="nav-link" href="main">
          <img
            src="../assets/misc/greennjr_logo.svg"
            alt=""
            class="retreatLogoMobile"
          />
        </a>
      </div>
      <nav class="navbar navbar-expand-lg nav" id="nav-menu">
        <div class="container-fluid wy">
          <ul class="navbar-nav mx-auto mb-2 mb-md-0 nav-justified w-100 try">
            <ion-icon
              name="close-outline"
              class="header_close"
              id="close-menu"
              @click="hideMenu"
            ></ion-icon>
            <div class="logoMobiles">
              <a class="nav-link" href="main">
                <img
                  src="../assets/misc/beigenjr_logo.svg"
                  alt=""
                  class="retreatLogoMobile"
                />
              </a>
            </div>
            <li class="nav-item d-none d-xxl-block">
              <a class="nav-link" href=""></a>
            </li>

            <li class="nav-item">
              <a class="nav-link" href="#aboutsection" @click="hideMenu"
                >ABOUT</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#schedulesection" @click="hideMenu"
                >SCHEDULE</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#speakersection" @click="hideMenu"
                >SPEAKERS</a
              >
            </li>
            <li class="nav-item d-none d-xl-block">
              <a class="nav-link" href=""></a>
            </li>
            <li class="nav-item d-none d-lg-block">
              <a class="nav-link" href="main">
                <img
                  src="../assets/misc/greennjr_logo.svg"
                  alt=""
                  class="retreatLogo"
                />
              </a>
            </li>
            <li class="nav-item d-none d-xl-block">
              <a class="nav-link" href=""></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#partnershipsection" @click="hideMenu"
                >PARTNERSHIP</a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#faqsection" @click="hideMenu">FAQ</a>
            </li>

            <li class="nav-item">
              <div>
                <a
                  class="nav-link golde" id="twiNavbarRegister"
                  href="https://myjli.com/retreat/register/wellness.php"
                  >REGISTER</a
                >
              </div>
            </li>
            <li class="nav-item d-none d-xxl-block">
              <a class="nav-link" href=""></a>
            </li>
          </ul>
        </div>
      </nav>
      <ion-icon
        name="menu-outline"
        class="header_toggle"
        id="toggle-menu"
        @click="toggleMenu"
      ></ion-icon>
    </header>

    <div class="headers">
      <div class="treeBackground">
        <img src="../assets/Pics/HeroImage.png" alt="" class="img-fluid" />

        <div class="wrapper">
          <div class="headContent">
            <div class="headTest">
              <h1 class="mainT">THE SPIRITUALITY AND WELLNESS SUMMIT</h1>
              <p class="aboutTitle noBottomPadding">{{ wellnessDetails.date }}</p> 
              <p class="dedicationText">{{ wellnessDetails.dedication }}</p>
            </div>
            <div class="greenSpace">
              <a href="https://myjli.com/retreat/register/wellness.php" id="twiHeroRegister">
                <button class="greenButton">REGISTER</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mainSpace">
      <a name="aboutsection"></a>

      <div class="aboutSection">
        <div class="wrapper">
          <div class="row align-items-center content aboutNav">
            <div class="col-md-4">
              <div class="image">
                <img
                  :src="`${$baseApiUrl}${imagePaths.aboutImagePath}`"
                  alt=""
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-6">
              <div>
                <div class="aboutTitle titleSpace">ABOUT THE EVENT</div>
                <div class="aboutText">{{ wellnessDetails.about }}</div>
              </div>
            </div>
            <div class="col-md-1"></div>
          </div>
          <div class="spaceOut">
            <div class="row align-items-center content">
              <div class="col-md-6 order-2 order-md-1">
                <div>
                  <div class="aboutTitle titleSpace">OUR GOAL</div>
                  <div class="aboutText sideSpace">
                    {{ wellnessDetails.goal }}
                  </div>
                </div>
              </div>
              <div class="col-md-6 order-1 order-md-2">
                <div>
                  <img
                    :src="`${$baseApiUrl}${imagePaths.goalImagePath}`"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="scheduleSection">
        <div class="navS"><a name="schedulesection"></a></div>
        <div class="wrapper">
          <div class="scheduleTitle">
            <h2 class="green">SCHEDULE</h2>
          </div>

          <div class="row">
            <div class="col-md-7">
              <div class="schedule">
                <div v-if="wellnessDetails.schedule">
                  <ul class="byeLine">
                    <li
                      v-for="(schedules, index) in wellnessDetails.schedule"
                      :key="schedules.time"
                    >
                      <div class="row" v-bind="index">
                        <div class="col-sm-4">
                          <div class="sGold">{{ schedules.value.time }}</div>
                        </div>
                        <div class="col-sm-8">
                          <div class="sProgram">
                            <div class="activity">
                              {{ schedules.value.title }}
                            </div>
                            <div class="activityInfo">
                              {{ schedules.value.description }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-4">
              <div class="eventInfo">
                <div class="topicS">
                  <div class="sTitle">TOPICS INCLUDE</div>
                  <div class="greenLight">
                    <div v-if="wellnessDetails.topics">
                      <ul>
                        <li
                          v-for="(tops, index) in wellnessDetails.topics"
                          :key="tops.topic"
                        >
                          <div v-bind:data-bs-target="index">
                            {{ tops.value.topic }}
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div v-if="wellnessDetails.topicNotes">
                      <ul class="byeLine">
                        <li
                          v-for="(notes, index) in wellnessDetails.topicNotes"
                          :key="notes.note"
                        >
                          <div v-bind:data-bs-target="index">
                            <div v-html="notes.value.note"></div>
                            <!-- {{ notes.value.note }} -->
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="cost">
                  <div class="sTitle">COST: {{ wellnessDetails.cost }}</div>
                  <div class="greenLight">
                    <p v-html="wellnessDetails.costDescription"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="locationSection">
        <div class="row">
          <div class="col-md-6 order-2 order-md-1">
            <div class="location">
              <h1 class="green">LOCATION</h1>
              <div class="sTitle">
                <div v-html="wellnessDetails.location"></div>
              </div>
            </div>
          </div>
          <div class="col-md-6 order-1 order-md-2">
            <div class="locationPicture">
              <img
                :src="`${$baseApiUrl}${imagePaths.locationImagePath}`"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="speakerSection">
        <a name="speakersection"></a>

        <div class="wrapper">
          <div class="speakerTitle">
            <h2 class="green">PROGRAM SPEAKERS</h2>
          </div>
          <div>
            <div class="speakers" v-if="wellnessDetails.speakers">
              <ul class="row speakRow">
                <li
                  v-for="(speaker, index) in wellnessDetails.speakers"
                  :key="speaker.name"
                  class="col-xl-3 col-lg-4 col-sm-6 speaker-container"
                >
                  <div v-bind:data-bs-target="index">
                    <div class="image-conatiners speakPic">
                      <div class="image-container">
                        <div class="picOut">
                          <div class="imgRel">
                            <img
                              :src="
                                this.$baseApiUrl +
                                '/' +
                                speaker.value.image.path
                              "
                              alt=""
                              class="img-fluid imgT"
                            />
                            <div class="greenBio">
                              <p
                                class="speaker-bio"
                                v-html="speaker.value.bio"
                              ></p>
                            </div>

                            <!-- <p class="speaker-bio">{{ speaker.value.bio }}</p> -->
                            <div class="speaker-overlay"></div>
                          </div>
                        </div>
                      </div>

                      <div class="body-section speak">
                        <p class="name">{{ speaker.value.name }}</p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="partnerSection">
        <a name="partnershipsection"></a>

        <div class="pWrap">
          <div class="pTitle">
            <h3 class="sTitle">IN PARTNERSHIP WITH</h3>
          </div>
          <div class="partners" v-if="wellnessDetails.partnership">
            <ul class="row speakRow">
              <li
                v-for="(parts, index) in wellnessDetails.partnership"
                :key="parts.logo"
                class="col-md-4 col-sm-6 speakPic"
              >
                <div v-bind:data-bs-target="index">
                  <a v-bind:href="parts.value.url">
                    <div>
                      <img
                        :src="$baseApiUrl + '/' + parts.value.logo.path"
                        class="img-fluid pimg"
                      />
                    </div>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="accredSection">
        <div class="wrapper">
          <div class="aLine">
            <div class="row aRow">
              <div class="col-md-1 emmy">
                <img
                  src="../assets/Pics/apasponsor.jpg"
                  alt=""
                  class="img-fluid acredImg"
                />
              </div>
              <div class="col-md-11">
                <div class="credA">
                  {{ wellnessDetails.accreditationTitle }}
                  <p
                    class="credA"
                    v-html="wellnessDetails.accreditationDescription"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="testSection">
        <img
          src="../assets/Pics/testBack.jpg"
          alt=""
          class="img-fluids testBack"
        />
        <div class="testUl">
          <div
            id="carouselExampleSlidesOnly"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="tWrap">
              <div v-if="wellnessDetails.testimonials">
                <div class="carousel-inner">
                  <div
                    v-for="(test, index) in wellnessDetails.testimonials"
                    :key="test.testimonial"
                    class="carousel-item"
                    :class="{ active: index == 0 }"
                  >
                    <div class="testText">
                      <p>"{{ test.value.testimonial }}"</p>
                      <p class="nameText">- {{ test.value.name }}</p>
                    </div>
                    <div>
                      <!-- <p>{{ test.value.Name }}</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="gb">
          <a href="https://myjli.com/retreat/register/wellness.php" id="twiFooterRegister">
            <button class="greenButton">REGISTER</button>
          </a>
        </div>
      </div>

      <div class="faqSection" v-if="wellnessDetails.faq">
        <a name="faqsection"></a>
        <div class="wrapper">
          <div class="faqSpace">
            <div class="row">
              <div class="col-md-4">
                <div class="fText">
                  <h3 class="faqTitle">FAQ</h3>
                  <p class="fInfo d-none d-md-block">
                    For more information email us at
                    <a
                      href="mailto:summit@wellnessinstitute.org"
                      class="greenLink"
                      >summit@wellnessinstitute.org</a
                    >
                    or call us at
                    <a href="tel: +17182216900p101" class="greenLink"
                      >718.221.6900 ext 101</a
                    >
                  </p>
                </div>
              </div>
              <div class="col-md-8">
                <ul class="fq">
                  <li
                    v-for="(faqs, index) in wellnessDetails.faq"
                    :key="faqs.question"
                  >
                    <div
                      class="accordion accordion-flush fqs"
                      id="accordionExample"
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="label">
                          <button
                            class="accordion-button faq collapsed fQuestion"
                            type="button"
                            data-bs-toggle="collapse"
                            v-bind:data-bs-target="'#collapseTwo' + index"
                            aria-expanded="true"
                            aria-controls="collapseTwo"
                          >
                            <div class="qSpace">
                              {{ faqs.value.question }}
                            </div>
                          </button>
                        </h2>
                        <div
                          v-bind:id="'collapseTwo' + index"
                          class="accordion-collapse collapse"
                          aria-labelledby="label"
                          data-bs-parent="#accordionExample"
                        >
                          <div
                            class="accordion-body faq fAnswer"
                            v-html="faqs.value.answer"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="fTexts">
              <p class="fInfo d-md-none">
                For more information email us at
                <a href="mailto:summit@wellnessinstitute.org" class="greenLink"
                  >summit@wellnessinstitute.org</a
                >
                or call us at
                <a href="tel: +17182216900p101" class="greenLink"
                  >718.221.6900 ext 101</a
                >
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import * as Sentry from "@sentry/vue";

export default {
  name: "WellnessInstitutePage",
  methods: {
    hideMenu() {
      document.getElementById("nav-menu").classList.remove("show");
    },
    toggleMenu() {
      document.getElementById("nav-menu").classList.toggle("show");
    },
  },

  data() {
    return {
      wellnessDetails: {},
      // locationDetails: {},
      imagePaths: {
        aboutImagePath: "",
        goalImagePath: "",
        locationImagePath: "",
      },
      // speakerData: [],
    };
  },
  mounted() {
    fetch(
      process.env.VUE_APP_BASE_API_URL +
        "/api/singletons/get/njrWellnessSummit?token=" +
        process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = "/error";
        }
      })
      .then((data) => {
        this.wellnessDetails = data;
        this.imagePaths.aboutImagePath = data.aboutImage.path;

        this.imagePaths.goalImagePath = data.goalImage.path;
        this.imagePaths.locationImagePath = data.locationImage.path;
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = "/error";
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bh {
  font-size: 10px;
  color: #f2e4d6;
  padding-top: 10px;
  margin-bottom: -20px;
  margin-right: 13px;
}

.retreatLogo {
  max-width: 150px;
}

header {
  position: fixed;
  width: 100%;
  background-color: #fff;
  z-index: 10;
  margin-bottom: 50px;
}
/* .navSpace {
  padding-top: 190px;
  margin-top: 190px;
} */
#nav-menu {
  padding-top: 30px;
}

.logoMobile {
  display: none;
}

.nav-list {
  display: flex;
}

ul.try li:before {
  content: none;
}

.nav_item {
  margin: 0 8px;
}

.nav-link {
  line-height: 100px;
  font-family: "GroteskRegular";
  color: #3d4526;
  font-size: 16px;
  letter-spacing: 0.1em;
}

.nav-link:focus,
.nav-link:hover {
  color: #3d4526bc;
}

.dropdown-menu.show {
  background-color: transparent;
  margin-top: -42px;
  margin-left: 10px;
  border: none;
}

.dropdown-item {
  color: #3d4526;

  font-size: 15px;
  letter-spacing: 0.1em;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #3d4526bc;
  background-color: transparent;
}

.header_toggle,
.header_close {
  display: none;
}

.nav-link.golde {
  color: #b79b6e;
}

.nav-link.golde:focus,
.nav-link.golde:hover {
  color: #b79b6e66;
}

.dropdown-toggle::afer {
  content: none;
}

.accordion-button:focus {
  outline: none !important;
  box-shadow: none;
}

.headers {
  padding-top: 206px;
}

.treeBackground {
  position: relative;
}

.headContent {
  position: absolute;
  top: 10%;
}

.headTest {
  max-width: 75%;
}

.mainT {
  color: #3d4526;
  font-family: "ApertoBold";
  font-size: 52px;
  letter-spacing: 2.08px;
  line-height: 44px;
}
.noBottomPadding {
  padding-bottom: 0 !important;
}
.dedicationText {
  color: #3d4526;
  font-family: "IdealLightI";
  font-size: 20px;
  letter-spacing: 0.2px;
  padding-bottom: 14px;
}
.aboutSection {
  background-color: #faf6f3;
  padding-bottom: 63px;
  /* padding-top: 100px; */
  margin-top: -100px;
}
.aboutNav {
  padding-top: 200px;
}
.aboutTitle {
  color: #9e866f;
  font-family: "IdealSemiBold";
  font-size: 18px;
  padding-bottom: 24px;
  letter-spacing: 2.16px;
}

.aboutText {
  color: #3d4526;
  font-family: "IdealLight";
  font-size: 20px;
  letter-spacing: 0.2px;
}

.sideSpace {
  padding-right: 29px;
}

.scheduleSection {
  padding-bottom: 143px;
  margin-top: -100px;
}

.scheduleTitle {
  padding-top: 254px;
  padding-bottom: 42px;
  text-align: center;
}

.green {
  color: #3d4526;
  font-family: "ApertoBold";
  font-size: 42px;
  letter-spacing: 1.68px;
}

.schedule {
  background-color: #faf6f3;
  padding: 34px;
  padding-bottom: 17px;
}

.eventInfo:deep(a),
.faqSection:deep(a) {
  color: #9ba389;
}

.sGold {
  color: #9e866f;
  font-family: "IdealSemiBold";
  font-size: 16px;
  padding-top: 16px;
}

.sProgram {
  background-color: #ffffff;
  color: #3d4526;
  padding: 17px;
  border-radius: 0.5em;
  margin-bottom: 17px;
}

.activity {
  font-family: "IdealSemiBold";
  font-size: 16px;
}

.activityInfo {
  font-family: "IdealLIght";
  font-size: 14px;
}

.topicS {
  padding-top: 15px;
  padding-bottom: 42px;
}

.sTitle {
  color: #9e866f;
  font-family: "IdealSemiBold";
  font-size: 18px;
  padding-bottom: 15px;
  letter-spacing: 2.16px;
  text-transform: uppercase;
}

.greenLight {
  color: #3d4526;
  font-family: "IdealLight";
  font-size: 16px;
  letter-spacing: 0.16px;
  line-height: 28px;
}

ul {
  list-style-type: none;
  padding-left: 1.4rem;
}

ul li:before {
  content: "\2014";
  position: absolute;
  margin-left: -20px;
}

ul.byeLine {
  padding-left: 0;
}

ul.byeLine li:before {
  content: none;
}

.greenClick {
  color: #9ba389;
}

.locationSection {
  background-color: #faf6f3;
}
.location {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding-left: 30%;
}
.locationPicture {
  display: relative;
  max-height: 400px;
  width: 100%;
  overflow: hidden;
}
.locationPicture img {
  width: 100%;
  -ms-transform: translateY(-18%);
  -webkit-transform: translateY(-18%);
  transform: translateY(-18%);
}
.speakerSection {
  margin-top: -100px;
  /* padding-top: 106px; */
}
.speakerTitle {
  text-align: center;
  padding-top: 206px;
}

ul.speakRow li:before {
  content: none;
  padding-left: 0;
}

.speaker-containers {
  text-align: center;
  padding-top: 20px;
}
.image-container {
  width: 100%;
  height: auto;
}
.picOut {
  background: #d8c7b3 0% 0% no-repeat padding-box;
}
.imgRel {
  position: relative;
}

.imgT {
  mix-blend-mode: luminosity;
}
.speakers {
  /* padding-bottom: 130px; */
}
.speaker-bio {
  font-family: "IdealLight";
  color: #faf6f3;
  left: 25px;
  position: absolute;
  top: 25px;
  right: 20px;
  font-size: 15px;
  display: none;
  z-index: 10;
}
.greenBio:hover {
  background-color: #576351;
  opacity: 0.85;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.image-container:hover .speaker-bio {
  display: inline-block;
}
.speaker-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.speaker-overlay:hover {
  background-color: #576351;
  opacity: 0.85;
}

.body-section.speak {
  background-color: #9ba389;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 1px;
}
.name {
  font-family: "IdealMedium";
  font-size: 14px;
  color: #faf6f3;
  text-align: left;
  padding-left: 11px;
  padding-top: 5px;
}

.partners {
  padding-bottom: 130px;
}
.speakPic {
  padding-top: 24px;
  max-width: 321px;
}

.name {
  font-family: "IdealMedium";
  font-size: 18px;
  color: #faf6f3;
}

.desc {
  font-family: "IdealLight";
  font-size: 16px;
  color: #faf6f3;
}

.partnerSection {
  text-align: center;
  margin-top: -100px;
}

.pWrap {
  margin-right: auto;
  margin-left: auto;
  max-width: 900px;
  padding-right: 20px;
  padding-left: 20px;
}
.pTitle {
  padding-top: 230px;
}

.partners {
  padding-top: 27px;
  padding-bottom: 66px;
}

.pimg {
  max-width: 160px;
  max-height: 83px;
}

.grayI {
  font-family: "IdealLightI";
  font-size: 20px;
  color: #707070;
  margin-right: auto;
  margin-left: auto;
  max-width: 1000px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 119px;
}

.aLine {
  border-top: 1px solid #707070;
  margin-bottom: 182px;
}

.aRow {
  padding-top: 102px;
}

.acredImg {
  max-height: 91px;
}

.cred {
  font-family: "IdealMedium";
  color: #3d4526;
  font-size: 20px;
}

.credA {
  font-family: "IdealLightI";
  font-size: 20px;
  color: #3d4526;
}

.testSection {
  position: relative;
}

.img-fluids {
  width: 100%;
  height: 360px;
}

.testUl {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
}

ul.testUl {
  padding-left: 0;
  margin-bottom: 0;
}

ul.testUl li:before {
  content: none;
}

.tWrap {
  margin-right: auto;
  margin-left: auto;
  max-width: 720px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
}

.gb {
  margin-right: auto;
  margin-left: auto;
  max-width: 720px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  margin-top: -136px;
}

.testText {
  color: #3d4526;
  font-size: 20px;
  font-family: "IdealMedium";
}
.nameText {
  font-family: "IdealBook";
  font-size: 18px;
}

.greenButton {
  background-color: #576351;
  color: #fcfbf7;
  letter-spacing: 2.28px;
  padding: 13px 43px;
  font-size: 19px;
  border: none;
  font-family: "GroteskBold";
}

.faqSection {
  background-color: #faf6f3;
  /* padding-top: 219px; */
  /* margin-top: -219px; */
  margin-top: -70px;

  padding-bottom: 100px;
}
.faqSpace {
  padding-top: 270px;
  /* padding-top: 438px; */
}

.fText {
  padding-right: 50px;
}

.faqTitle {
  font-family: "ApertoBold";
  font-size: 52px;
  color: #3d4526;
  padding-top: 16px;
}

.fInfo {
  font-family: "IdealLight";
  font-size: 20px;
  color: #3d4526;
}

.greenLink {
  color: #354526;
  text-decoration: none;
}

ul.fq li:before {
  content: none;
}

.fqs {
  border-bottom: 1px solid #3d4526;
}

.fQuestion {
  font-family: "IdealMedium";
  font-size: 20px;
  color: #9ba389;
}

.fAnswer {
  font-family: "IdealLight";
  font-size: 16px;
  color: #3d4526;
}

.accordion-button.faq {
  background-color: #faf6f3;
  padding-left: 0px;
}

.accordion-button.faq::after {
  color: #343a40;
  /* padding-left: 20px; */
}

.accordion-button.faq:not(.collapsed) {
  box-shadow: none;
}

.accordion-button.faq:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-body.faq {
  background-color: #faf6f3;
  padding-left: 0px;
}
@media (max-width: 1755.98px) {
  .locationPicture img {
    width: 100%;
    -ms-transform: translateY(0%);
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
}
@media (max-width: 1440.98px) {
  .headContent {
    top: 4%;
    padding-left: 31px;
  }
  .mainT {
    font-size: 2.65rem;
  }
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.65rem;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0em;
    padding-left: 0em;
  }
}

@media (max-width: 991.98px) {
  .bh {
    padding-top: 10px;
    margin-bottom: -40px;
  }
  ol,
  ul {
    padding-right: 2em;
  }
  ul.speakRow {
    padding-right: 0em;
  }
  .wy {
    margin-left: -12px;
  }
  .logoMobile {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
  }
  .logoMobile img {
    width: 150px;
    margin-top: 57px;
  }
  .logoMobiles {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: -17px;
    padding-bottom: 50px;
  }
  .logoMobiles img {
    width: 150px;
  }
  .header_toggle {
    position: absolute;
    display: block;
    color: #b79b6e;
    font-size: 50px;
    padding-left: 87vw;

    margin-top: -90px;
  }
  .header_close {
    position: absolute;
    display: block;
    color: #b79b6e;
    font-size: 49px;
    right: 0;
    top: 0;
    padding-top: 45px;
    padding-right: 8vw;
  }
  .nav {
    position: fixed;
    top: 0;
    left: 100%;
    background-color: #3d4526;
    width: 100%;
    height: 100vh;
    padding: 24px 0;
    z-index: 100;
    transition: 0.5s;
  }
  .try {
    padding-right: 70px;
    padding-left: 70px;
  }
  .navbar-nav {
    padding-top: 60px;
    position: absolute;
    top: 0;
  }
  .dropdown-menu.show {
    background-color: transparent;
    margin-top: -17px;
  }
  .nav-item {
    margin-bottom: 1rem;
    margin-top: 1.2rem;
    border-bottom: #b79b6e solid 1px;
    width: 100%;
    margin-right: 10px;
  }
  .dropdown-item:hover {
    background-color: transparent;
    color: #f2e4d6b3;
  }
  .nav-link {
    padding-left: 0px;
    color: #f2e4d6;
    font-size: 4.2vw;
    line-height: 5.9vw;
    text-align: left;
  }
  .nav-link:hover,
  .nav-link:focus {
    color: #f2e4d6b3;
  }

  .nav-link.golde {
    background-color: #b79b6e;
    color: #f2e4d6;
    text-align: center;
    margin-top: 65px;
    padding-top: 17px;
    padding-bottom: 17px;
  }
  .nav-link.golde:hover {
    color: #f2e4d6b3;
  }
  .show {
    left: 0;
  }
}

@media (max-width: 767.98px) {
  .header_toggle {
    padding-left: 87vw;
  }
  .faqTitle {
    text-align: center;
  }
  .headers {
    padding-top: 272px;
  }
  .headContent {
    top: -23%;
    padding-left: 46px;
  }

  .mainT {
    font-size: 2rem;
  }

  .greenSpace {
    margin-top: -10px;
  }
  .greenButton {
    padding: 9px 26px;
    font-size: 1rem;
  }
  .green {
    font-size: 2rem;
  }
  .aboutSection {
    /* margin-top: 0px; */
  }
  .aboutNav {
    /* padding-top: 0px; */
  }
  .sTitle {
    font-size: 0.85rem;
  }
  .titleSpace {
    padding-top: 34px;
    padding-bottom: 14px;
  }
  .spaceOut {
    padding-bottom: 50px;
  }
  .scheduleSection {
    margin-top: -75px;
    /* margin-top: 0px; */
  }
  .scheduleTitle {
    padding-top: 150px;
    /* padding-top: 80px; */
  }
  .topicS {
    padding-top: 60px;
  }
  .locationSection {
    background-color: #fff;
  }
  .location {
    position: none;
    top: -35%;
    transform: none;
    padding-top: 35px;
    padding-left: 20px;
    padding-bottom: 21px;
    max-width: 55%;
    background-color: #faf6f3;
    margin-left: auto;
    margin-right: auto;
  }
  .locationPicture {
    max-height: 400px;
    width: 100%;
    overflow: none;
  }

  .locationPicture img {
    width: 100%;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
  }
  .speakerSection {
    /* padding-top: 50px; */
    margin-top: -70px;
  }
  .speakerTitle {
    padding-top: 140px;
  }
  .partnerSection {
    margin-top: -65px;
  }
  .pTitle {
    padding-top: 160px;
  }

  .aRow {
    text-align: center;
  }
  .emmy {
    padding-bottom: 40px;
  }
  .gb {
    margin-top: -111px;
    padding-top: 5px;
  }
  .faqSection {
    margin-top: -65px;

    /* padding-bottom: 100px; */
    /* margin-top: 0px;
    padding-top: 150px; */
  }
  .faqSpace {
    padding-top: 130px;
    /* padding-top: 0px; */
  }
  .fText {
    padding-right: 0px;
  }
  ul.fq {
    padding-left: 0px;
    padding-right: 0px;
  }
  .fTexts {
    padding-top: 20px;
  }
}
@media (max-width: 575.98px) {
  ul {
    padding-right: 0em;
  }
  .try {
    padding-right: 40px;
    padding-left: 40px;
  }
  .logoMobile {
    width: 100px;
  }
  .logoMobile img {
    width: 100px;
  }
  .logoMobiles img {
    width: 100px;
  }
  .header_toggle {
    margin-top: -69px;
    padding-left: 83vw;
    padding-right: 8vw;
  }
  .nav-link.golde {
    margin-top: -30px;
  }
  .headers {
    /* padding-top: 250px; */
  }

  .headContent {
    top: -44%;
  }

  .headTest {
    max-width: 64%;
  }

  .mainT {
    font-size: 35px;
    line-height: 29px;
  }

  .aboutTitle {
    font-size: 13px;
    padding-bottom: 19px;
  }

  .greenButton {
    font-size: 13px;
    padding: 11px 20px;
  }

  .aboutText {
    font-size: 16px;
  }

  .sideSpace {
    padding-right: 0px;
  }
}
@media (max-width: 459.98px) {
  .header_toggle {
    padding-left: 81vw;
    padding-right: 8vw;
  }
}

@media (max-width: 428.99px) {
  .headers {
    padding-top: 284px;
  }

  .headContent {
    top: -68%;
    padding-left: 17px;
    /* top: -100%; */
  }

  .headTest {
    max-width: 90%;
  }

  .aboutSection {
    margin-top: -70px;
  }
  .aboutNav {
    padding-top: 140px;
  }

  .titleSpace {
    padding-top: 34px;
    padding-bottom: 14px;
  }

  .spaceOut {
    padding-top: 33px;
    padding-bottom: 0px;
  }

  .scheduleSection {
    padding-bottom: 33px;
    margin-top: -50px;
  }

  .scheduleTitle {
    padding-top: 146px;
    padding-bottom: 0px;
  }

  .green {
    font-size: 28px;
    letter-spacing: 1.12px;
  }

  .schedule {
    padding: 6px 24px 9px;
    /* padding: 24px;
    padding-bottom: 9px; */
  }

  .sProgram {
    margin-bottom: 5px;
  }

  .sGold {
    padding-left: 5px;
    padding-bottom: 11px;
  }

  .topicS {
    padding-top: 46px;
    padding-bottom: 0px;
  }

  .sTitle {
    font-size: 13px;
    padding-bottom: 16px;
    letter-spacing: 1.68px;
  }

  ul.byeLine {
    margin-bottom: 0rem;
    padding-bottom: 27px;
  }

  .locationSection {
    background-color: #fff;
  }

  .location {
    position: none;
    top: -23%;
    transform: none;
    padding-top: 35px;
    padding-left: 20px;
    padding-bottom: 21px;
    max-width: 84%;
    background-color: #faf6f3;
    margin-left: auto;
    margin-right: auto;
  }

  .speakerSection {
    margin-top: -70px;
    /* padding-top: 17px; */
  }

  ul.speakRow {
    padding-left: 0px;
    margin-bottom: 0px;
  }

  .speakerTitle {
    padding-top: 140px;
  }

  .speakers {
    padding-bottom: 0px;
  }

  .speakPic {
    padding-bottom: 31px;
  }

  .partnerSection {
    padding-top: 36px;
    margin-top: -50px;
  }

  .partners {
    padding-top: 0px;
    padding-bottom: 54px;
    /* padding-top: 18px; */
  }

  .pWrap {
    max-width: 290px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .pTitle {
    padding-top: 127px;
  }

  .pimg {
    max-width: 130px;
    max-height: 70px;
    /* padding-top: 40px; */
  }

  .pCol {
    padding-top: 18px;
  }

  .grayI {
    font-size: 18px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 56px;
  }
  .aLine {
    margin-bottom: 95px;
  }

  .aRow {
    padding-top: 61px;
    text-align: center;
  }

  .emmy {
    padding-bottom: 37px;
  }

  .cred {
    line-height: 28px;
    padding-bottom: 10px;
  }

  .testBack {
    /* max-height: 326px; */
  }

  .testUl {
    top: 50%;
    left: 0%;
    transform: translate(0%, -41%);
    padding-bottom: 70px;
  }

  .tWrap {
    padding-right: 43px;
    padding-left: 43px;
  }
  .gb {
    padding-top: 41px;
    /* margin-top: -111px; */
  }

  .faqSection {
    margin-top: -60px;
    /* padding-top: 131px; */
    padding-bottom: 51px;
  }
  .faqSpace {
    padding-top: 120px;
  }

  .faqTitle {
    text-align: center;
    font-size: 40px;
  }

  .fText {
    padding-right: 0px;
  }

  ul.fq {
    padding-left: 0px;
    /* padding-right: 0px; */
  }

  .accordion-button.faq {
    padding-right: 0px;
    align-items: unset;
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .qSpace {
    width: 85%;
  }

  .fTexts {
    padding-top: 20px;
  }

  .fInfo {
    font-family: "IdealLightI";
    font-size: 18px;
    color: #707070;
  }
}

@media (max-width: 390.99px) {
  .headers {
    padding-top: 284px;
  }

  .headContent {
    top: -77%;
    padding-left: 12px;
    /* top: -140%; */
  }

  .headTest {
    max-width: 90%;
  }
}
@media (max-width: 375.98px) {
  .header_toggle {
    padding-left: 74vw;
    padding-right: 8vw;
  }
}
</style>
