<template>
  <div class="beigeBackground">
    <div class="pageContainer">
      <!-- mainSpace -->
      <div>
        <div class="contactForm">
          <div class="headSpace">
            <h1 class="goldH1">Contact Us</h1>
          </div>
          <div class="spacer">
            <div class="wrapper">
              <!-- <h2 class="contactHeader" style="text-align: center;">Contact Us</h2> -->
              <div class="row">
                <div class="col-md-7">
                  <div class="formPart">
                    <div class="formHeader">
                      <!-- <h1 class="goldH1">Contact Us</h1> -->
                      <div>
                        <p v-show="responseSuccess" class="responseMessage">
                          Thank you for getting in touch! Your message has been
                          successfully submitted. We will get back to you soon.
                        </p>
                        <p v-show="responseError" class="responseMessage">
                          There was an error with your submission. Please try
                          again later.
                        </p>
                      </div>
                      <div class="lightParagraph">
                        <p>We look forward to hearing from you!</p>
                      </div>
                    </div>
                    <div class="formContact">
                      <form @submit.prevent="handleSubmit">
                        <div class="row">
                          <!-- <label for="Name">NAME</label> -->
                          <div class="form-group col-md-6">
                            <!-- <label for="firstName">FIRST NAME</label> -->
                            <input
                              type="text"
                              class="form-control"
                              id="firstName"
                              placeholder="First Name"
                              v-model="firstName"
                              required
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <!-- <label for="lastName">LAST NAME</label> -->
                            <input
                              type="text"
                              class="form-control"
                              id="lastName"
                              placeholder="Last Name"
                              v-model="lastName"
                              required
                            />
                          </div>
                        </div>
                        <div class="form-group">
                          <!-- <label for="inputEmail">EMAIL ADDRESS</label> -->
                          <input
                            type="email"
                            class="form-control"
                            id="inputEmail"
                            placeholder="Enter your email here"
                            v-model="email"
                            required
                          />
                        </div>

                        <div class="form-group">
                          <!-- <label for="Message">MESSAGE</label> -->
                          <textarea
                            name="Message"
                            id="Message"
                            placeholder="Message"
                            maxlength="5000"
                            class="form-control"
                            v-model="message"
                            required
                          ></textarea>
                        </div>

                        <div class="form-group">
                          <div class="submitBtn">
                            <input
                              type="submit"
                              value="Submit"
                              data-wait="Please wait..."
                              class="form-control submitBtn"
                            />
                          </div>
                        </div>
                        <span class="recaptchaText"
                          >This site is protected by reCAPTCHA and the Google
                          <a href="https://policies.google.com/privacy"
                            >Privacy Policy</a
                          >
                          and
                          <a href="https://policies.google.com/terms"
                            >Terms of Service</a
                          >
                          apply.</span
                        >
                      </form>
                    </div>
                  </div>
                </div>
                <div class="col-md-1"></div>

                <div class="col-md-3">
                  <div class="sideInfo">
                    <div class="details-wrap">
                      <div class="lightParagraph">
                        <!-- <p> -->
                        For reservations or more information, call
                        <a href="tel: +18775738732" style="color: #b79b6e"
                          >1.877.573.8732</a
                        >
                      </div>
                    </div>
                    <div class="details-wrap">
                      <div class="lightParagraph">
                        832 Eastern Parkway
                        <br />
                        Brooklyn, NY 11213
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useReCaptcha } from "vue-recaptcha-v3";

export default {
  name: "ContactPage",
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded();

      // Execute reCAPTCHA with action "submit_contact_form".
      const token = await executeRecaptcha("submit_contact_form");
      // Do stuff with the received token.
      return token;
    };

    return {
      recaptcha,
    };
  },
  data() {
    return {
      responseSuccess: false,
      responseError: false,
      firstName: "",
      lastName: "",
      email: "",
      message: "",

    };
  },
  methods: {
    handleSubmit() {
      this.recaptcha().then((token) => {
        const formData = {
          form: {
            Name: this.firstName + " " + this.lastName,
            Email: this.email,
            Message: this.message,
            reply_to: this.email,
            recaptchaToken: token,
          },
        };
        fetch(
          `${this.$baseApiUrl}/api/forms/submit/njrContactForm?token=${process.env.VUE_APP_API_TOKEN}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        )
          .then((response) => {
            // on status 200 alert success
            if (response.status === 200) {
              this.responseSuccess = true;
              this.firstName = "";
              this.lastName = "";
              this.email = "";
              this.message = "";
            } else {
              this.responseError = true;
            }
          })
          .catch((error) => {
            console.error("Error!", error);
            this.responseError = true;
          });
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contactForm {
  padding-top: 100px;
}
.form-group {
  margin-top: 20px;
  color: #12275c;
  font-family: "GroteskLight";
  padding-bottom: 20px;
}
.formPart {
  margin-top: 30px;
  padding: 10px;
  padding-bottom: 60px;
}
label {
  margin-bottom: 10px;
}
.form-control {
  background: #fbf7f3;
  border: none;
  border-bottom: 0.5px solid #b79b6e;
  border-radius: 0;
  padding: 0;
  height: 40px;
}
.form-control:focus {
  box-shadow: none;
  background: #f5eee6;
}
#Message {
  background: #fbf7f3e9;
  border-bottom: none;
  border: #b79b6e 1px solid;
  height: 200px;
}
textarea#Message.form-control {
  padding-left: 5px;
}
textarea ::placeholder {
  padding-right: 50px;
}
#Message:focus {
  background: #f5eee6;
}
.sideInfo {
  margin-top: 30px;
  padding-top: 50px;
}
/* Needs to be fixed from name to color size and font */
.lightParagraph {
  color: #29326e;
  font-family: "GroteskLight";
}
.submitBtn {
  background: #b79b6e;
  color: #ffffff;
}
.submitBtn:hover {
  background: #b79b6eb1;
}
.details-wrap {
  margin-bottom: 30px;
}
.goldLogo {
  text-align: center;
}

.recaptchaText {
  font-family: "GroteskLight";
  color: #29326e;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.recaptchaText a {
  text-decoration: none;
  font-family: "GroteskRegular";
  color: #b79b6e;
}

.responseMessage {
  background-color: #b79b6e;
  color: #ffffff;
  padding: 10px;
  margin-bottom: 10px;
}
@media (max-width: 575.98px) {
  .contactForm {
    padding-top: 50px;
  }
}
</style>
