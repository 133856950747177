<script>
import * as Sentry from "@sentry/vue";

export default {
  name: "FacultyPage",
  methods: {
    changeSeeAllStatus() {
      this.seeAll = true;
    },
    changeWorkshopSeeAllStatus() {
      this.workshopSeeAll = true;
      this.workshopsShowAllButton = false;
    },
    changeVisibilityStatus(status) {
      this.isPastFaculty = status;
    },
    navigateOnClick(url) {
      window.location.href = url;
    },
    detectResize() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.getDimensions, 250);
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth;
      this.showAll = this.width < 751;
      this.seeAll = this.showAll === false ? false : this.seeAll;
      if (window.matchMedia("(max-width: 800px)").matches) {
        this.workshopMobileView = true;
        this.workshopMediumView = false;
        this.workshopLargeView = false;
        this.newSize = "Small (Below 800 pixels)";
        if (this.newSize !== this.currentSize) {
          this.currentSize = this.newSize;
          this.workshopsShowAllButton = this.workshopSeeAllMedium;
          this.workshopSeeAll = false;
        }
      } else if (window.matchMedia("(min-width: 801px) and (max-width: 1200px)").matches) {
        this.workshopMobileView = false;
        this.workshopMediumView = true;
        this.workshopLargeView = false;
        this.newSize = "Medium (Between 800 and 1200 pixels)";
        if (this.newSize !== this.currentSize) {
          this.currentSize = this.newSize;
          this.workshopsShowAllButton = this.workshopSeeAllMedium;
          this.workshopSeeAll = false;
        }
      } else {
        this.workshopMobileView = false;
        this.workshopMediumView = false;
        this.workshopLargeView = true;
        this.newSize = "Large (Greater than 1200 pixels)";
        if (this.newSize !== this.currentSize) {
          this.currentSize = this.newSize;
          this.workshopsShowAllButton = this.workshopSeeAllLarge;
          this.workshopSeeAll = false;
        }
      }
    },
    getCorrectCurrentArray() {
      if (this.showAll && this.seeAll == false)
        return this.currentFaculty.slice(0, 4);
      return this.currentFaculty;
    },
    getCorrectPastArray() {
      if (this.showAll && this.seeAll == false)
        return this.pastFaculty.slice(0, 4);
      return this.pastFaculty;
    },
    getWorkshopsArray() {
      if (this.workshopMobileView && this.workshopSeeAll == false) {
        return this.workshopsDetails.entries.slice(0, 2);
      }
      if (this.workshopMediumView && this.workshopSeeAll == false) {
        return this.workshopsDetails.entries.slice(0, 2);
      }
      if (this.workshopLargeView && this.workshopSeeAll == false) {
        return this.workshopsDetails.entries.slice(0, 3);
      }
      return this.workshopsDetails.entries;
    },
    calculateBackground(location) {
      return {
        backgroundImage: `url(${this.$baseApiUrl + location})`,
        backgroundColor: "#f5e7da"
      }
    },
    calculateBackgroundForWorkshops(location) {
      return {
        backgroundImage: `url(${this.$baseApiUrl + location})`
      }
    }
  },
  data() {
    return {
      facultyDetails: {},
      currentFaculty: [],
      pastFaculty: [],
      workshopsDetails: {},
      previousRetreatDetails: {},
      isPastFaculty: false,
      width: document.documentElement.clientWidth,
      timeout: false,
      showAll: false,
      seeAll: false,
      workshopMobileView: false,
      workshopSeeAll: false,
      workshopSeeAllLarge: false,
      workshopSeeAllMedium: false,
      workshopsShowAllButton: true,
      workshopMediumView: false,
      workshopLargeView: false,
      currentSize: "",
      newSize: "",
    };
  },
  mounted() {
    window.addEventListener('resize', this.detectResize);

    fetch(
      this.$baseApiUrl +
      "/api/collections/get/njrFaculty?token=" +
      process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = "/error";
        }
      })
      .then((data) => {
        this.facultyDetails = data.entries;
        this.currentFaculty = this.facultyDetails.filter(
          (faculty) => faculty.isCurrent
        );
        
        if (this.currentFaculty.length === 0) {
          this.isPastFaculty = true;
        }

        this.pastFaculty = this.facultyDetails.filter(
          (faculty) => !faculty.isCurrent
        );
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = "/error";
      });

    fetch(
      this.$baseApiUrl +
      "/api/singletons/get/njrPreviousRetreatDetails?token=" +
      process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = "/error";
        }
      })
      .then((data) => {
        this.previousRetreatDetails = data;
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = "/error";
      });

    fetch(
      this.$baseApiUrl +
      "/api/collections/get/njrPastWorkshops?token=" +
      process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = "/error";
        }
      })
      .then((data) => {
        this.workshopsDetails = data;
        this.workshopSeeAllLarge = this.workshopsDetails.entries.length > 3;
        this.workshopSeeAllMedium = this.workshopsDetails.entries.length > 2;
        this.getDimensions();
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = "/error";
      });

  },
  unmounted() {
    window.removeEventListener('resize', this.detectResize);
  },
};
</script>


<template>
  <!-- Header Section -->
  <section class="faculty-page-header">
    <img src="../assets/images/faculty-page-header.png" alt="" class="faculty-page-header__img">


    <h1 class="faculty-page-header__title">
      An unparalleled assembly of the world’s most sought-after Jewish thinkers, theologians, academics, and
      educators.
    </h1>

    <button type="button" class="faculty-page-header__button" id="facultyHeroRegister"
      @click="navigateOnClick('https://njr.myjli.com/?_ga=2.256426571.1181635165.1686603604-173800360.1682354722')">Register
      <svg fill="none" viewBox="0 0 46 16">
        <g>
          <path
            d="M36.3008 15.9999C36.3006 14.9396 36.5094 13.8896 36.9151 12.9099C37.3208 11.9302 37.9156 11.04 38.6653 10.2902C39.4151 9.54043 40.3053 8.94569 41.285 8.53997C42.2647 8.13425 43.3147 7.92551 44.3751 7.92566"
            stroke="#B99D6C" />
          <path
            d="M36.3008 -0.14856C36.3017 0.910347 36.5106 1.95876 36.9156 2.93715C37.3201 3.92002 37.9147 4.81331 38.6654 5.56573C39.415 6.31578 40.3051 6.91074 41.2848 7.31658C42.2632 7.72158 43.3116 7.93049 44.3705 7.93144"
            stroke="#B99D6C" />
          <path d="M0.714844 8H45.2863" stroke="#B99D6C" />
        </g>
      </svg>
    </button>
  </section>
  <!-- Presenters Section -->

  <section class="faculty-page-presenters">
    <h2 class="heading2-v2">Presenters</h2>

  </section>

  <div class="faculty-page-presenters__card-section" v-if="facultyDetails">
    <div class="faculty-page-wrapper">
      <div class="faculty-page-presenters__filters-section">
        <p v-bind:class="{ 'active-status': !isPastFaculty }" @click="changeVisibilityStatus(false)">Current</p>
        <p v-bind:class="{ 'active-status': isPastFaculty }" @click="changeVisibilityStatus(true)">Past</p>
      </div>

      <!-- Current -->
       <div v-bind:class="{ 'hidePhotoCollection': isPastFaculty, 'photoGrid': true }">
        <ul class="row faculty-page-row">
          <li v-for="(faculty, index) in getCorrectCurrentArray()" :key="index"
            class="col-xl-3 col-md-4 col-6 faculty-page-item">


            <button class="facultyButton" type="button" data-bs-toggle="modal" v-bind:data-bs-target="'#mymodal' + index">


              <div :style="calculateBackground(faculty.image.path)" class="faculty-page-item__image-container">
                <div class="faculty-page-item__image-overlay"></div>

              </div>
            </button>
            <div class="modal fade" v-bind:id="'mymodal' + index" tabindex="-1" aria-labelledby="facultyModalLabel"
              aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <div>
                      <div class="facultyName">
                        {{ faculty.name }}
                      </div>
                      <div class="facultyLocation">
                        {{ faculty.location }}
                      </div>
                    </div>

                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div>{{ faculty.bio }}</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="facultyInfo">
              <div class="faculty-card__name">{{ faculty.name }}</div>
              <div class="faculty-card__location">
                {{ faculty.location }}
              </div>
            </div>

          </li>
          
          <!-- if no current presenters -->
          <li v-if="getCorrectCurrentArray().length === 0" class="col-12 faculty-page-item">
            <h2 class="heading2-v2 coming-soon-message">Coming Soon</h2>
          </li>


        </ul>
      </div>

      <!-- Past -->
      <div v-bind:class="{ 'hidePhotoCollection': !isPastFaculty, 'photoGrid': true }">
        <ul class="row faculty-page-row">
          <li v-for="(faculty, index) in getCorrectPastArray()" :key="faculty.name"
            class="col-xl-3 col-md-4 col-6 faculty-page-item">


            <button class="facultyButton" type="button" data-bs-toggle="modal"
              v-bind:data-bs-target="'#pastPresentersModal' + index">
              <div :style="calculateBackground(faculty.image.path)" class="faculty-page-item__image-container">
                <div class="faculty-page-item__image-overlay"></div>

              </div>
            </button>
            <div class="modal fade" v-bind:id="'pastPresentersModal' + index" tabindex="-1"
              aria-labelledby="facultyModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <div>
                      <div class="facultyName">
                        {{ faculty.name }}
                      </div>
                      <div class="facultyLocation">
                        {{ faculty.location }}
                      </div>
                    </div>

                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div>{{ faculty.bio }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="facultyInfo">
              <div class="faculty-card__name">{{ faculty.name }}</div>
              <div class="faculty-card__location">
                {{ faculty.location }}
              </div>
            </div>

          </li>
        </ul>
      </div>

    </div>
    <!--  -->
  </div>
  <div>
  </div>
  <div>
  </div>
  <div v-bind:class="{ 'hideShowAll': !showAll, 'faculty-page-presenters__link-container': true }">
    <a href="#" class="faculty-page-presenters__link" @click.prevent="changeSeeAllStatus">See All</a>
  </div>
  <!-- Workshop Section -->
  <section class="workshop-section">
    <h2 class="workshop-section__title">Past Workshops</h2>
    <div class="workshop-section__wrapper">
      <button class="workshop-section__card" v-for="(workshop) in getWorkshopsArray()" :key="workshop._id" type="button"
        @click="navigateOnClick(workshop.link)">
        <div class="workshop-section__card-overlay"></div>
        <div :style="calculateBackgroundForWorkshops(workshop.image.path)" class="workshop-section__card-image"></div>
        <button type="button" class="workshop-section__button">
          <img src="../assets/images/play-button-arrow.svg" alt="">
        </button>
        <h3 class="workshop-section__card-title">{{ workshop.title }}</h3>
        <div class="workshop-section__line"></div>
        <h4 class="workshop-section__card-speaker">{{ workshop.name }}</h4>
      </button>
    </div>
    <a href="#" v-bind:class="{ 'hideWorkshopsShowAll': !workshopsShowAllButton, 'workshop-section__link': true }"
      @click.prevent="changeWorkshopSeeAllStatus">See All</a>
  </section>
</template>


<style scoped>
/* Section - Previous Code */

.modal-content {
  background-color: var(--color-light);
}

.modal-body {
  color: var(--color-blue);
  font-family: "GroteskLight";
}

.facultyInfo {
  margin-bottom: 30px;
  margin-top: 10px;
  padding-left: 6px;
}

/* 1000px */
@media (max-width: 62.5em) {
  .facultyInfo {
    margin-top: 4px;
  }
}

.facultyButton {
  border: none;
  background-color: #fff;
}

.facultyName {
  font-family: "GroteskSemiBold";
}

.facultyLocation {
  font-family: "GroteskRegular";
}

/* Section - Buttons and Typography */

.heading2-v2 {
  font-family: "CanelaLight";
  font-weight: 300;
  font-size: 48px;
  line-height: 64px;
  color: var(--color-dark-gold);
}

@media (max-width: 75em) {
  .heading2-v2 {
    font-size: 38.4px;
    line-height: 51.2px;
  }
}

/* Section - Header */

.faculty-page-header {
  background-color: var(--color-blue);
  display: grid;
  grid-template-rows: 113px max-content 280px;
  grid-template-columns: 1fr max-content 1fr;
}

.faculty-page-header__img {
  width: 1127px;
  height: 576px;
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.faculty-page-header__title {
  margin: 0;
  width: 649px;
  font-family: "CanelaLight";
  font-weight: 300;
  font-size: 56px;
  line-height: 64px;
  color: var(--color-peach);

  grid-row: 3 / 4;
  grid-column: 2 / 3;
  transform: translateY(-39%) translateX(94px);
}

.faculty-page-header__button {
  background-color: transparent;
  color: var(--color-gold);
  fill: var(--color-gold);
  text-transform: uppercase;
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.14em;
  border: 0.79834px solid var(--color-gold);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 21px;
  cursor: pointer;

  padding: 27px 29.71px;
  grid-row: 3 / 4;
  grid-column: 2 / 3;
  align-self: center;
  justify-self: end;
  transform: translateY(-45px);
  border-radius: 0px !important;
}

.faculty-page-header__button:hover {
  color: #FAF6F3;
  background-color: var(--color-gold);
}

.faculty-page-header__button:hover svg path {
  stroke: var(--color-beige);
}

.faculty-page-header__button svg {
  width: 44.57px;
}

/* 1200px */
@media (max-width: 75em) {
  .faculty-page-header {
    grid-template-rows: max-content 317px;
  }

  .faculty-page-header__img {
    width: 320px;
    height: 211px;
    object-fit: cover;
    grid-row: 1 /2;
  }

  .faculty-page-header__title {
    width: 282px;
    font-size: 32px;
    line-height: 36px;
    font-weight: 300;
    font-family: "CanelaLight";
    font-weight: 300;
    color: var(--color-peach);
    grid-row: 2 / 3;
    transform: translateY(-62px) translateX(29px);
  }

  .faculty-page-header__button {
    font-size: 10px;
    line-height: 13px;
    padding: 17px 18.26px;
    border: 0.5px solid #B99D6C;
    gap: 13px;
    width: 138px;

    transform: translateY(0) translateX(29px);
    grid-row: 2/3;
    align-self: end;
    justify-self: start;
    margin-bottom: 47px;
  }

  .faculty-page-header__button svg {
    width: 28px;
  }
}

/* Section - Presenters */
.faculty-page-presenters {
  padding-top: 114px;
  display: grid;
}

.coming-soon-message {
    margin-bottom: 50px;
    text-align: center;
    margin-top: 50px;
}

/* 1000px */
@media (max-width: 62.5em) {
  .faculty-page-presenters {
    padding-top: 50px;
  }
}

.faculty-page-presenters h2 {
  justify-self: center;
}

.faculty-page-presenters__card-section {
  grid-column: full-start / full-end;
  padding-bottom: 86px;
}

@media (max-width: 767px) {
  .faculty-page-presenters__card-section {
    padding-bottom: 0;
  }
}

.faculty-page-presenters__filters-section {
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  display: flex;
  gap: 43px;
  padding-top: 24px;
  padding-bottom: 37px;
  padding-right: 11px;
  padding-left: 11px;
}

/* 1000px */
@media (max-width: 62.5em) {
  .faculty-page-presenters__filters-section {
    font-size: 14px;
    line-height: 18px;
    gap: 26px;
    padding-bottom: 25px;
    padding-left: 6px;
  }
}

@media (max-width: 767px) {
  .faculty-page-presenters__filters-section {
    /* gap: 24px; */
    /* padding-top: 29px; */
    /* font-size: 14px;
    line-height: 18px; */
    padding-left: 0;
    justify-content: center;
  }
}

.faculty-page-presenters__filters-section p:first-child {
  color: rgba(23, 36, 75, 0.45098);
  cursor: pointer;
}

.faculty-page-presenters__filters-section p:last-child {
  color: rgba(23, 36, 75, 0.45098);
  cursor: pointer;
}

.faculty-page-presenters__link-container {
  display: flex;
  grid-column: center-start / center-end;
  margin-bottom: 55px;
  justify-content: center;
}

.faculty-page-presenters__link {
  text-decoration: none;
  position: relative;
  width: max-content;
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: var(--color-dark-gold);
  display: block;
}

.faculty-page-presenters__link:hover {
  color: var(--color-grey);
}

.faculty-page-presenters__link::after {
  content: "";
  height: .65px;
  background-color: var(--color-dark-gold);
  display: block;
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.faculty-page-presenters__link:hover::after {
  background-color: var(--color-grey);
}

.faculty-card__name {
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;
  color: var(--color-blue);
}

/* 1000px */
@media (max-width: 62.5em) {
  .faculty-card__name {
    font-size: 10px;
    line-height: 13px;
  }
}

/* 400px */
@media (max-width: 25em) {
  .faculty-card__name {
    font-size: 10px;
    line-height: 10px;
  }
}

.faculty-card__location {
  font-family: "GroteskLight";
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--color-blue);
}

@media (max-width: 62.5em) {
  .faculty-card__location {
    font-size: 10px;
    line-height: 13px;
  }
}

/* 400px */
@media (max-width: 25em) {
  .faculty-card__location {
    font-size: 10px;
    line-height: 14px;
  }
}

/* Section - General */

.faculty-page-row {
  --bs-gutter-x: 55px;
  list-style: none;
  padding-left: 0;
}

@media (max-width: 767px) {
  .faculty-page-row {
    --bs-gutter-x: 0;
  }
}

.faculty-page-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1100px;
  width: 100%;
}

@media (max-width: 1199px) {
  .faculty-page-wrapper {
    max-width: 774px;
  }
}

@media (max-width: 1000px) {
  .faculty-page-wrapper {
    max-width: 448px;
  }
}

@media (max-width: 767px) {
  .faculty-page-wrapper {
    max-width: 351px;
  }
}

@media (max-width: 500px) {
  .faculty-page-wrapper {
    max-width: 331px;
  }
}

h2,
p {
  margin: 0;
  padding: 0;
}

.hidePhotoCollection {
  display: none;
}

.hideShowAll {
  display: none;
}

.active-status {
  color: var(--color-blue) !important;
}

/* Section - Faculty */

.faculty-page-item {
  text-decoration: none;
}

.faculty-page-item__image-container {
  position: relative;
  display: inline-block;
  background-blend-mode: LUMINOSITY, NORMAL;
  width: 264px;
  height: 264px;
  background-size: contain;
}

/* 1000px */
@media (max-width: 62.5em) {
  .faculty-page-item__image-container {
    width: 152px;
    height: 152px;
  }
}


.faculty-page-item__image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(23, 36, 75, 0.2);
  opacity: 0;
}

.faculty-page-item__image-overlay:hover {
  opacity: 1;
}

/* Section - Workshops */

.workshop-section__card {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 32px 36px 43px;
  max-width: 360px;
  max-height: 520px;
  border: none;
  margin: 0;
  padding: 0;
}

.workshop-section__card:hover .workshop-section__button {
  display: block;
}

.workshop-section__card-image {
  display: inline-block;
  background-blend-mode: darken;
  width: 360px;
  height: 520px;
  background-size: contain;

  grid-row: 1 / -1;
  grid-column: 1 / 2;
  z-index: 1;
}

.workshop-section__card-overlay {
  grid-row: 1 / -1;
  grid-column: 1 / 2;
  width: 360px;
  height: 520px;
  background-color: rgba(14, 25, 57, 0.5);
  z-index: 2;
}

.workshop-section {
  padding-top: 116px;
  padding-bottom: 114px;
  background-color: var(--color-beige);
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
}

.workshop-section__title {
  color: var(--color-dark-gold);
  font-family: "CanelaLight";
  font-size: 48px;
  font-weight: 300;
  line-height: 64px;
  padding-bottom: 54px;
  grid-column: 2 / 3;
  text-align: center;
}

.workshop-section__wrapper {
  width: 1128px;
  grid-column: 2 / 3;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  row-gap: 24px;
  column-gap: 23px;
  grid-auto-flow: dense;
}

.workshop-section__button {
  display: none;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  justify-self: center;
  align-self: center;
  border-radius: 50%;
  background-color: transparent;
  height: 48px;
  width: 48px;
  border: 1px solid var(--color-gold);
  z-index: 2;
  margin-top: 22px;
  position: relative;
}

.workshop-section__button img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-30%, -50%);
}

.workshop-section__link {
  text-decoration: none;
  position: relative;
  width: max-content;
  font-family: "GroteskMedium";
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: var(--color-dark-gold);
  display: block;
  grid-column: 2 / 3;
  padding-top: 68px;
  justify-self: center;
}

.workshop-section__link:hover {
  color: var(--color-grey);
}

.workshop-section__link::after {
  content: "";
  height: .65px;
  background-color: var(--color-dark-gold);
  display: block;
  width: 100%;
  position: absolute;
  bottom: -2px;
  left: 0;
}

.workshop-section__link:hover::after {
  background-color: var(--color-grey);
}

.workshop-section__card-title {
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  z-index: 2;
  font-family: "CanelaLight";
  font-weight: 300;
  font-size: 40px;
  line-height: normal;
  color: var(--color-light);
  text-align: center;
  align-self: end;
  width: 257px;
  justify-self: center;
  margin: 0;
}

.workshop-section__card-speaker {
  font-family: "GroteskRegular";
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: var(--color-light);
  text-align: center;
  grid-row: 3 / 4;
  grid-column: 1 / 2;
  z-index: 2;
  align-self: end;
  margin: 0;
}

.workshop-section__line {
  content: "";
  height: 1px;
  background: var(--color-gold);
  display: block;
  width: 26.3px;
  grid-row: 2 / 3;
  grid-column: 1 / 2;
  z-index: 2;
  align-self: end;
  justify-self: center;
}

.hideWorkshopsShowAll {
  display: none;
}

/* 1200px */
@media (max-width: 75em) {
  .workshop-section__wrapper {
    width: 745px;
  }
}

/* 800px */
@media (max-width: 50em) {
  .workshop-section__wrapper {
    width: 320px;
    row-gap: 35px;
  }

  .workshop-section__card {
    width: 320px;
    height: 460px;
    grid-template-rows: 1fr 28px 34px 38px;
  }

  .workshop-section__card-image {
    width: 320px;
    height: 460px;
  }

  .workshop-section__card-overlay {
    width: 320px;
    height: 460px;
  }

  .workshop-section__title {
    font-size: 32px;
    padding-bottom: 17px;
  }

  .workshop-section {
    padding-top: 42px;
    padding-bottom: 51px;
  }

  .workshop-section__button {
    width: 42.511px;
    height: 42.511px;
  }

  .workshop-section__link {
    font-size: 13.033px;
    line-height: 19.549px;
    letter-spacing: 0.521px;
    padding-top: 47px;
  }

  .workshop-section__card-title {
    width: 227px;
    font-size: 35.425px;
    line-height: normal;
  }

  .workshop-section__card-speaker {
    font-size: 14.17px;
    line-height: 16.314px;
  }

  .workshop-section__line {
    width: 23.293px;
  }

}
</style>