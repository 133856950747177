<template>
  <div class="beigeBackground">
    <div class="fof">
      <h1 class="goldH1">NJR APP</h1>
      <p>If you  have not been automatically redirected to download the Retreat App, <br/> search your phones App store for National Jewish Retreat</p>
    </div>
  </div>
</template>


 <script>
export default {
  name: "AppPage",
  props: {},
  beforeCreate() {
    window.location.href = 'https://onelink.to/u6r2wz';
  }
};
</script>

<style scoped>
* {
  transition: all 0.6s;
}
.fof {
  height: 100vh;
  text-align: center;
  padding-top: 300px;
  /* position: relative; */
}

.fof h1 {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px #b7936eaa;
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
@media (max-width: 575.98px) {
  .fof {
    padding-top: 200px;
  }
}
a {
color: #b79b6e;
}
</style>
