<template>
  <div class="pageContainer">
    <div class="mainSpace">
      <div class="headSpace">
        <h1 class="goldH1">Shluchim Info</h1>
      </div>
      <div class="spacer">
        <div class="wrapper">
            <div class="pageContent" v-if="pageContent" v-html="pageContent"></div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import * as Sentry from "@sentry/vue";

export default {
  name: "ShluchimInfoPage",
  data() {
    return {
      pageContent: {},
    };
  },
  mounted() {
    fetch(
      this.$baseApiUrl +
        "/api/singletons/get/njrRetreatDetails?token=" +
        process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = '/error';
        }
      })
      .then((data) => {
        this.pageContent = data.shluchimPage;
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = '/error';
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
li {
  list-style: none;
}
.mainSpace {
  padding-top: 100px;
}
.goldenH2 {
  color: #b79b6e;
  font-family: "CanelaRegular";
  line-height: 73px;
}
.pageContent {
  height: 400px;
  text-align: center;

}
.pageSecContainer:last-child .pageSec {
  border: 0;
}
/* >>> enables styles to be applied within the v-html (bc of way vue works) */
.kidContent:deep(a) {
  text-decoration: none;
  font-family: "GroteskRegular";
  color: #b79b6e;
}
.kidContent:deep() {
  font-family: "GroteskRegular";
}
</style>
