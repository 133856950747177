<script>
import * as Sentry from "@sentry/vue";
import moment from "moment";

export default {
  name: "PricingPage",
  data() {
    return {
      pricingDetails: {},
      pricingNotes: "",
      surchargeDate: Date(),
      surchargePercent: 0,
      imagePaths: {
        accommodationImage1Path: "",
      },
    };
  },
  mounted() {
    fetch(
      this.$baseApiUrl +
        "/api/singletons/get/njrPricing?token=" +
        process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = "/error";
        }
      })
      .then((data) => {
        this.pricingDetails = data.price;
        this.pricingNotes = data.notes;
        this.surchargeDate = data.surchargeDate ? data.surchargeDate : Date();
        this.surchargePercent = data.surchargePercent
          ? data.surchargePercent
          : 0;
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = "/error";
      });

    fetch(
      this.$baseApiUrl +
        "/api/singletons/get/njrLocationDetails?token=" +
        process.env.VUE_APP_API_TOKEN
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          Sentry.captureException(response);
          window.location.href = "/error";
        }
      })
      .then((data) => {
        this.imagePaths.accommodationImage1Path = data.accommodationImage1.path;
      })
      .catch((err) => {
        Sentry.captureException(err);
        window.location.href = "/error";
      });
  },
  methods: {
    calculatePrice(price) {
      const today = moment().format("YYYY-MM-DD");
      const surchargeDate = moment(this.surchargeDate).format("YYYY-MM-DD");

      // if no surcharge defaults to today so surcharges DO apply
      if (moment(surchargeDate).isSameOrBefore(today)) {
        price = price.replace(/\D/g, "");
        price = parseInt(price);
        price = price * (1 + this.surchargePercent);
        price = price / 100;
        price = this.$options.filters.toCurrency(price);
      }
      return price;
    },
  },
  filters: {
    toCurrency: function (value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
  },
};
</script>


<template>
  <div>
    <div class="pricing">
      <div class="priceHead">
        <div class="wrapper">
          <div class="headSpace">
            <h1 class="goldH1">Pricing</h1>
          </div>
        </div>
      </div>
      <div class="pricingPhoto">
        <div class="wrapper">
          <div class="bedroomPhoto">
            <img
              :src="`${$baseApiUrl}${imagePaths.accommodationImage1Path}`"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
      <div class="wrapper">
        <div class="pricingCharts">
          <br />
          <br />
          <div class="accordion accordion-flush" id="pricingPageTable">
            <div
              class="accordion-item"
              v-for="(room, roomIndex) in pricingDetails"
              :key="roomIndex"
            >
              <h2
                class="accordion-header"
                :id="`flush-heading${roomIndex}`"
                v-if="roomIndex > 0"
              >
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="`#flush-collapse${roomIndex}`"
                  aria-expanded="false"
                  :aria-controls="`flush-collapse${roomIndex}`"
                >
                  {{ room.value.type }}
                </button>
              </h2>
              <div
                :id="`flush-collapse${roomIndex}`"
                class="accordion-collapse"
                :class="{ collapse: roomIndex != 0 }"
                :aria-labelledby="`flush-heading${roomIndex}`"
                data-bs-parent="#pricingPageTable"
              >
                <div class="accordion-body">
                  <div class="mediumWrapper">
                    <table class="table albums">
                      <thead>
                        <tr>
                          <th scope="col">
                            <div class="goldenStandard">
                              {{ room.value.type }}
                            </div>
                          </th>

                          <th
                            v-for="(roomDate, roomDateIndex) in room.value
                              .dates"
                            :key="roomDateIndex"
                            scope="col"
                            class="centerMe"
                          >
                            <div class="regularBlue">
                              {{ roomDate.value.weekDays }}
                            </div>
                            <div class="lightBlue">
                              {{ roomDate.value.monthDays }}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(occupancy, occupancyIndex) in room.value
                            .occupancies"
                          :key="occupancyIndex"
                        >
                          <th scope="row">
                            <div class="regularBlue">
                              {{ occupancy.value.occupancyName }}
                            </div>
                          </th>
                          <td
                            v-for="(
                              occupancyPrice, occupancyPriceIndex
                            ) in occupancy.value.prices"
                            :key="occupancyPriceIndex"
                          >
                            <div
                              class="goldFill"
                              v-if="occupancyPrice.value.amount"
                            >
                              <a
                                class="cleanUp pricingButton"
                                :href="occupancyPrice.value.url"
                                >{{
                                  calculatePrice(occupancyPrice.value.amount)
                                }}</a
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottomInfo">
            <div
              class="smallBlue"
              id="pricingNotes"
              v-html="pricingNotes"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style scoped>
/* th.row.squeeze {
  padding-right: 100px;
} */

.centerMe {
  text-align: center;
}
.priceHead {
  padding-top: 100px;
  background-color: #fbf7f3;
  height: 550px;
}

.bedroomPhoto {
  margin-top: -265.5px;
  max-height: 540px;
  overflow: hidden;
  /* position: relative; */
}
.suitePhoto {
  max-height: 540px;
  overflow: hidden;
}
.pricingCharts {
  padding-bottom: 100px;
}
.standardPricing {
  background-color: #fbf9f4;
}
.goldenStandard {
  font-family: "CanelaRegular";
  font-size: 30px;
  color: #b79b6e;
  padding-bottom: 10px;
  font-weight: 400;
}
.mediumWrapper {
  margin-right: auto; /* centers content */
  margin-left: auto; /* centers content */
  max-width: 990px; /* sets width of content */
  padding-right: 10px; /* lil padding */
  padding-left: 10px; /* lil padding */
  padding-top: 25px;
  padding-bottom: 25px;
  /* width: 90%; */
}
table.albums {
  border-collapse: separate;
  border-spacing: 5px 5px;
}
thead {
  margin-top: 50px;
}
th {
  border-color: transparent;
  padding: 0px;
  margin-top: 90px;
}
.tez {
  padding-top: 15px;
  /* background: red; */
}
td {
  border-bottom: none;
  padding: 0px;
}
.goldFill {
  text-align: center;
  background: #b79b6e;
  padding-top: 6px;
  padding-bottom: 6px;
  color: #fbf9f4;
  font-family: "GroteskBold";
}
.cleanUp {
  text-decoration: none;
  color: #fbf9f4;
}
.accordion-body {
  background-color: #fbf9f4;
  padding: 0px;
}
.accordion-button {
  background-color: #17244b;
  color: #f2e4d6;
  font-family: "GroteskMedium";
}
.accordion-button:hover {
  background-color: #17244beb;
}
.lightBlue {
  margin-bottom: 10px;
}
.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23F2E4D6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.accordion-button:focus {
  border-color: none;
  box-shadow: none;
}
.smallBlue {
  padding-top: 55px;
  font-size: 15px;
  font-family: "GroteskLight";
  color: #17244b;
}
.smallBlue p {
  margin-bottom: 5px;
}

#pricingNotes:deep(a) {
  text-decoration: none;
  font-family: "GroteskRegular";
  color: #b79b6e;
}

@media (max-width: 768px) {
  .priceHead {
    margin-top: -60px;
  }
}
@media (max-width: 575.98px) {
  .goldH1 {
    font-size: 8vw;
  }
  .priceHead {
    height: 350px;
  }
  .bedroomPhoto {
    margin-top: -150px;
  }
  .goldenStandard {
    font-size: 3.6vw;
  }
  .regularBlue {
    font-size: 2.7vw;
  }
  .lightBlue {
    font-size: 2.5vw;
  }
  .goldFill {
    font-size: 2vw;
    font-weight: n;
  }
}
@media (max-width: 459px) {
  .regularBlue {
    font-size: 2vw;
  }
  .lightBlue {
    font-size: 2vw;
  }
}
@media (max-width: 425px) {
  .priceHead {
    height: 280px;
  }
  .bedroomPhoto {
    margin-top: -100px;
  }
  .lightBlue {
    font-size: 1.6vw;
  }
}
@media (max-width: 376px) {
  /* .pop {
    padding-right: 80px;
    margin-right: -70px;
  } */
}
</style>
